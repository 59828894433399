import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

export default function Form({ defaultValues, children, onSubmit, validationSchema = {} }) {
  const methods = useForm({
     defaultValues,
    resolver: yupResolver(validationSchema)
   });
  const { register, handleSubmit, formState:{ errors }, reset  } = methods;


  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, reset))} autoComplete="on">
      {React.Children.map(children, child => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register,
                key: child.props.name,
                error: errors[child.props.name]
              }
            })
          : child;
       })}
    </form>
  );
}
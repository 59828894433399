import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Cart from '../pages/Cart';
import { BusinessContext } from './BusinessProvider';
import PaymentMethodsFactory from 'helpers/PaymentMethodsFactory';

import _set from 'lodash/set'
import useLocalStorage from 'helpers/useLocalStorage';
import { cleanObject, getActiveTimeslot, getReference } from 'helpers/index';
import { firebaseFunctions } from 'helpers/firebase';
import dayjs from 'dayjs';
import { CALENDAR_DATE_FORMAT, CALENDAR_DAY_NAME_FORMAT } from 'config';
import { DEFAULT_BOOKABLE_DAYS } from 'config';
import {ShopContext} from "./ShopProvider";

const now = dayjs();

const DEFAULT_PRODUCTS = [];
const DEFAULT_TOTAL_PRICE = 0;
const DEFAULT_SIZE = 0;
const DEFAULT_MIN_ORDER = 0;
const DEFAULT_ORDER_BAR = false;
export const DEFAULT_USER = {
    telephone: '',
    name: '',
    email: '',
    privacy: false,
    over18: false,
    address: {
        formatted_address: '',
        lat: '',
        lng: '',
        number: '',
        city: '',
        zip: '',
    }
};

const DEFAULT_DELIVERY = {
    time: '',
    date: ''
}
const DEFAULT_TAKEAWAY = {
    time: '',
    date: ''
}

const defaultState = {
    products: DEFAULT_PRODUCTS,
    totalPrice: DEFAULT_TOTAL_PRICE,
    user: DEFAULT_USER,
    type: 'table',
}


function CartProvider(props)
{
    const [showOrderBar, setOrderBarVisibility] = useState(DEFAULT_ORDER_BAR);
    const [products, setProducts] = useState(DEFAULT_PRODUCTS);
    const [totalPrice, setTotalPrice] = useState(DEFAULT_TOTAL_PRICE);
    const [totalPriceWithDelivery, setTotalPriceWithDelivery] = useState(DEFAULT_TOTAL_PRICE);
    const [minOrderForDelivery, setMinOrder] = useState(DEFAULT_MIN_ORDER);
    const [size, setSize] = useState(DEFAULT_TOTAL_PRICE);
    const [user, setUser] = useState(DEFAULT_USER);
    const [payment, setPayment] = useState('cash');
    const [delivery, setDelivery] = useState(DEFAULT_DELIVERY);
    const [takeaway, setTakeaway] = useState(DEFAULT_TAKEAWAY);
    const [error, setError] = useState(false);
    const [type, setType] = useState('table');

    const [isShopOpen, setShopOpen] = useState(true);

    const business = useContext(BusinessContext);
    const shop = useContext(ShopContext);

    const [storedCart, storeCart] = useLocalStorage("cart");

    const location = useLocation();


    useEffect(() =>
    {
        if(!business?.id) return;

        let totalPriceCounter = DEFAULT_TOTAL_PRICE;
        let sizeCounter = DEFAULT_SIZE;

        for(const index in products)
        {
            const item = products[index]

            sizeCounter += item.qty;

            if(business.reservation)
            {
                // Cerco i limiti di acquisto per il menu selezionato
                const selectedMenuLimit = business.reservation.menuLimits.find(i => i.id === shop.menu);

                // E vedo se la categoria di appartenenza del prodotto esiste ed ha dei limiti
                const limit = (selectedMenuLimit?.categories||[]).find(i => i.id === item.category_id)?.limit
                //Se ci sono limiti non li metto nel computo del prezzo
                if(limit > 0) continue;
            }

            totalPriceCounter += (item.price * item.qty);
            if (item.ingredients && item.ingredients.length)
            {
                item.ingredients.forEach(ingredient =>
                {
                    if (ingredient.price)
                    {
                        totalPriceCounter += ingredient.price * item.qty;
                    }
                });
            }
        }
        setTotalPrice(totalPriceCounter);
        setSize(sizeCounter);

    }, [products, business, shop.menu]);

    // Mostro la barra del carrello solo se il business è abilitato alla ricezione
    // degli ordini e non è disabilitato
    useEffect(() =>
    {
        if (!business || Object.keys(business).length === 0) return;
        setMinOrder(business?.delivery?.minimumOrder || 0);
        setOrderBarVisibility(business?.allowOrder && !business.disabled)
    }, [business]);


    useEffect(() =>
    {
        setProducts(storedCart?.products || DEFAULT_PRODUCTS);
        setUser(storedCart?.user || DEFAULT_USER);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() =>
    {
        const deliveryCost = type === 'delivery' ? business?.delivery?.cost || 0 : 0

        setTotalPriceWithDelivery(totalPrice + deliveryCost)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPrice, type, business.delivery])

    useEffect(() =>
    {
        storeCart({ products, user });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, user])

    const timeslots = useMemo(() =>
    {
        if (type === 'table') return false;

        let bookableDaysArray = {};

        for (let i = 0; i < (business.bookableDays || DEFAULT_BOOKABLE_DAYS); i++)
        {
            //Calcolo il giorno +i da oggi
            const date = now.add(i, 'day');
            const dateDayName = date.format(CALENDAR_DAY_NAME_FORMAT).toLowerCase();

            //Recupero gli slot disponibili per tale giorno
            const activeTimeSlotForDate = getActiveTimeslot(business[type]?.calendar[dateDayName]);

            //Se ci sono disponibilità l'aggiungo all'oggetto
            if (activeTimeSlotForDate.length > 0)
                _set(
                    bookableDaysArray,
                    date.locale('it').format(CALENDAR_DATE_FORMAT),
                    getActiveTimeslot(business[type]?.calendar[dateDayName])
                )
        }

        return Object.keys(bookableDaysArray).length > 0 ? bookableDaysArray : false

    }, [type, business]);



    /*
    * Imposta se lo shop può ricevere ordini nel giorno/ora attuale
    */
    useEffect(() =>
    {
        //    const deliveryTimeslot = getActiveTimeslot(business?.delivery?.calendar[dayName])
        //    const takeawayTimeslot = getActiveTimeslot(business?.takeaway?.calendar[dayName])
        const isNotTable = getReference(location.search) === ''

        if (type === 'table' && isNotTable)
        {
            if (business.delivery?.active)
            {
                setType('delivery');
            } else if (business.takeaway?.active)
            {
                setType('takeaway')
            }
        }
        const queryParams = new URLSearchParams(location.search);
        setShopOpen(
            queryParams.get("reference") || business.delivery?.active || business.takeaway?.active
        );
    }, [type, location.search, business.delivery, business.takeaway])

    /*
    * Aggiunge un prodotto al carello
    */
    function addProduct(product)
    {
        const indexOfSameProduct = products.findIndex(i => i.id === product.id && shop.menu === i.menu);

        // Se quel prodotto è stato già aggiunto al carrello o è un prodotto modulare
        if (indexOfSameProduct === -1 || product.ingredients)
            return setProducts(products.concat({ ...product, localId: Math.random(), menu: shop.menu
            }));

        let tempProducts = [...products];

        tempProducts[indexOfSameProduct]['qty'] += product.qty;

        if (product.notes)
        {
            const otherNotesAlreadyPresent = tempProducts[indexOfSameProduct]['notes'] !== '';
            tempProducts[indexOfSameProduct]['notes'] += otherNotesAlreadyPresent ? `------------- \r\n ${product.notes}` : product.notes;
        }

        levelUp(product.qty, product.id)
        setProducts(tempProducts);
    }

    function cleanProductWithUnActiveMenu(menus)
    {
        setProducts(products.filter(i => !i.menu || menus.includes(i.menu)))
    }


    function levelUp(qty, id)
    {
        const animationNode = document.getElementById(`addToCart__${id}`);

        if (!animationNode) return;

        let child = document.createElement('span');
        child.innerHTML = `+${qty}`;

        animationNode.appendChild(child);

        // setTimeout(() => animationNode.removeChild(child), 10000)
    }

    function incrementProductQty(product)
    {
        const productIndex = products.findIndex(i => i.localId === product.localId);

        if (productIndex === -1) return;

        let tempProducts = [...products];
        tempProducts[productIndex]['qty'] += 1;

        setProducts(tempProducts);
    }

    function decrementProductQty(product)
    {
        const productInListIndex = products.findIndex(i => i.localId === product.localId);

        if (productInListIndex === -1) return;

        if (products[productInListIndex].qty === 1)
            return removeProduct(product);

        let tempProducts = [...products];
        tempProducts[productInListIndex]['qty'] -= 1;

        setProducts(tempProducts);
    }

    function removeProduct(product)
    {
        //TODO Chiedere Conferma
        setProducts(products.filter(i => i.localId !== product.localId))
    }

    function reset()
    {
        setProducts(DEFAULT_PRODUCTS);
        setTotalPrice(DEFAULT_TOTAL_PRICE);
        setSize(DEFAULT_TOTAL_PRICE);
    }

    /*
    * Invia l'ordine al server e ne gestisce le fasi a seconda del
    * metodo di pagamento
    */
    async function placeOrder()
    {
        if (!isUserValid())
            return;

        const paymentFactory = await new (await PaymentMethodsFactory(payment)).default(business)

        const order = firebaseFunctions().httpsCallable('orderV12');
        const queryParams = new URLSearchParams(location.search);

        const orderData = {
            products: products.map(i => ({ id: i.id, name: i.name, price: i.price, qty: i.qty, notes: i.notes, ingredients: i.ingredients, menu: i.menu })),
            total: totalPriceWithDelivery,
            user: user,
            qrCodeId: queryParams.get("reference"),
            businessId: business.id,
            payment_type: payment,
            host: window.location.protocol + '//' + window.location.host,
            type: type
        }

        if (type === 'delivery')
        {
            orderData['delivery'] = delivery;
        }

        if (type === 'takeaway')
        {
            orderData['takeaway'] = takeaway;
        }

        paymentFactory.beforeSubmit();

        return order(cleanObject(orderData))
            .then(async ({ data }) =>
            {
                return paymentFactory.afterSubmit(data);
            })
            .catch(error =>
            {
                console.error('failed', error);
                setError({ message: error.message })
                return;
            });
    }


    function updateUser(key, value)
    {
        const tmpObj = _set(user, key, value)
        setUser({ ...user, ...tmpObj });
    }

    function updateDeliveryTakeaway(key, value, type = 'delivery')
    {

        if (type === 'delivery')
        {
            const tmpObj = _set(delivery, key, value)
            setDelivery({ ...delivery, ...tmpObj });
        }

        if (type === 'takeaway')
        {
            const tmpObj = _set(takeaway, key, value)
            setTakeaway({ ...takeaway, ...tmpObj });
        }
    }



    function isUserValid()
    {
        setError(false); //Reset error

        let validationErrors = {};

        if (business.askUserName && user.name === '')
            _set(validationErrors, 'validation.name', 'Nome e Cognome obbligatori');

        if (user.telephone === '')
            _set(validationErrors, 'validation.telephone', 'Telefono Obbligatorio');

        if (user.privacy === false)
            _set(validationErrors, 'validation.privacy', 'Devi accettare la privacy per proseguere');


        if (type !== 'table')
        {
            if (user.over18 === false)
                _set(validationErrors, 'validation.over18', 'Devi avere compiuto almeno 18 anni per effettuare un\'ordine');

        }

        if (type === 'takeaway')
        {
            if (!takeaway.time)
                _set(validationErrors, 'validation.delivery_time', 'Orario di consegna selezionato non valido.');
            if (!takeaway.date)
                _set(validationErrors, 'validation.delivery_date', 'Data di consegna selezionato non valido.');
        }


        if (type === 'delivery')
        {
            if (!delivery.time)
                _set(validationErrors, 'validation.delivery_time', 'Orario di consegna selezionato non valido.');

            if (!delivery.date)
                _set(validationErrors, 'validation.delivery_date', 'Data di consegna selezionato non valido.');


            if (user.address.email === '')
                _set(validationErrors, 'validation.email', 'Indirizzo email obbligatorio.');

            if (user.address.street === '')
                _set(validationErrors, 'validation.address_street', 'Indirizzo di consegna obbligatorio.');

            if(user.address.number === '')
                _set(validationErrors, 'validation.address_number', 'Numero civico obbligatorio.');

            if(user.address.lat === '')
                _set(validationErrors, 'validation.lat', 'L\'indirizzo non risulta correttamente selezionato');

        }

        if (validationErrors.validation)
        {
            setError({ message: 'Si sono verificati i seguenti errori', ...validationErrors });
            return false;
        }

        return true;
    }

    function clear()
    {
        setProducts(DEFAULT_PRODUCTS)
    }

    const stateToPublic = {
        addProduct,
        removeProduct,
        incrementProductQty,
        decrementProductQty,
        placeOrder,
        setUser,
        updateUser,
        error,
        user,
        clear,
        size,
        minOrderForDelivery,
        totalPrice,
        products,
        payment,
        setType,
        setPayment,
        reset,
        type,
        delivery,
        takeaway,
        updateDeliveryTakeaway,
        totalPriceWithDelivery,
        timeslots,
        isShopOpen,
        cleanProductWithUnActiveMenu
    };


    return (
        <CartContext.Provider value={stateToPublic}>
            {props.children}
            <Cart hide={!showOrderBar} />
        </CartContext.Provider>
    );
}

export default CartProvider;

const CartContext = React.createContext(defaultState);

export { CartContext }

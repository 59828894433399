import React, { useEffect } from 'react';
import { PAYMENT_METHODS }  from 'config.js';

const PaymentMethods = ({business, onChange, value}) => {
    
    useEffect(() => { 
        
        if(!business.id) return;
        
        if(business.payment.methods.length > 0)
            onChange(business.payment.methods[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [business])

    function onClick(id) 
    { 
        onChange(id);
    }


    return (
        <div>
            <ul className="paymentMethods">
                {business && PAYMENT_METHODS
                    .filter(i => business?.payment?.methods.includes(i.id))
                    .map( i => (
                        <PaymentMethod 
                            selected={i.id === value}
                            label={i.label}
                            key={i.id}
                            id={i.id}
                            icon={i.icon}
                            onClick={onClick}
                        /> 
                ))}
            </ul>
            
        </div>
    );
}

export default PaymentMethods;


const PaymentMethod = ({label, id, icon, selected = false, onClick}) => (
    <li 
        onClick={onClick.bind(this, id)}
        className={`paymentMethods__item ${selected && 'selected'}`} 
        >
        <span className={`paymentMethods__item-icon fe-icon-${icon}`}/>
        <span>{label}</span>
        <div className="paymentMethods__item-check fe-icon-ok"></div>
    </li>
)
import React, { useContext } from 'react';
import { BusinessContext } from '../../providers/BusinessProvider';

import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import ProductCard from '../../components/ProductCard'

import { FULLPAGE_PRODUCT_TRANSITION_TIME_MS } from '../../config'

function Product() {

  const location = useLocation();
  const business = useContext(BusinessContext)


  const expandVariants = {
    minimize: (element_id) => {  
        const coords = document.getElementById(element_id)
          ?.getBoundingClientRect()||{left:0, rigth: 0, height:0, width:0}    
        return {
          left: coords.left,
          top: coords.top,
          width: coords.width,
          height: coords.height,
          paddingBottom: 0,
        }
    },
    full: {
      left: 0,
      top: 0,
      width: '100%',
      height: '100vh',
      paddingBottom: 66,
    }
  }


  return (    
      <motion.div
          className="expanded-product"
          custom={location?.state?.element_id}
          initial="minimize"
          animate="full"
          exit="minimize"
          variants={expandVariants}
          transition={{ duration: FULLPAGE_PRODUCT_TRANSITION_TIME_MS/1000 }}
      >       
          {location?.state?.product && <ProductCard 
            business={business}
            isExpanded
            product={location.state.product}
            blockOrder={business.blockOrder} /> }
        
      </motion.div>  
  );
}

export default Product;

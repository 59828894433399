import React from 'react';

function Checkbox ({children, name, register, className, error}) {
    return (
        <div className="input-wrapper">
        <label className={`checkbox-container ${className}`}>
            <input type="checkbox"
                     {...register(name)}
        />
            <span className="checkmark"></span>
            {children}            
            {error && <p className="input-error">{error?.message}</p>}
        </label>
        </div>
    )
}

export default Checkbox;
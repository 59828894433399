import React, { useState, useEffect, useContext } from 'react';
import Swiper, {Lazy} from "swiper";
import Product from '../../../components/ProductCard';
import { BounceLoader } from 'react-spinners';
import { BusinessContext } from '../../../providers/BusinessProvider';

let swiper;

Swiper.use([Lazy])

export const filterCondition = function(product, categories, menuId = false,  categoryId = false, business)
{
  const reservedProducts = business?.reservedProducts ? Object.values(business.reservedProducts) : [];
  
  //se è un prodotto riservato o coperto
  if([...reservedProducts,business?.coverChargeId].includes(product.id))
    return false;
  //Se è selezionata una categoria e non è la categoria del prodotto
  //Escludo il prodotto
  if(categoryId && product.category_id !== categoryId)
      return false;

  //Se la categoria del prodotto non è attiva nel menu corrente
  //Escludo il prodotto
  const productCategory = categories.find(i => i.id === product.category_id);
  if(
    productCategory &&
    productCategory.menus !== undefined &&
    (
      Array.isArray(productCategory.menus) &&
      !productCategory.menus.includes(menuId)
  ))
    return false;

  //Se il prodotto non è attivo nel menu corrente
  //Escludo il prodotto
  if(menuId && product.menus !== undefined && Array.isArray(product.menus) && !product.menus.includes(menuId))
    return false;

  return true;
}


function Products({products = [], menuId}) {

  //const [products, setProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const business = useContext(BusinessContext)

  useEffect(() => {
    if(products.length === 0) return;

    setLoaded(true);
  }, [products]);

  useEffect(() => {if(loaded) loadSlider()}, [loaded])


  useEffect( () => {
    if(!swiper) return;

    swiper.slideTo(0);
    swiper.slideReset();
    swiper.update();
    if(swiper.lazy) swiper.lazy.load();
  }, [products])

  function loadSlider()
  {
    swiper = new Swiper ('#productSlider.swiper-container', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 0,
      centeredSlides: true,
      centeredSlidesBounds: true,
      slideToClickedSlide: true,
      initialSlide: 0,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      preloadImages: false,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      speed:500,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 4,
       // loadOnTransitionStart: true
      },
      on: {
        lazyImageReady: (swiper) => {swiper.update()}
      }
    })

    window.onresize = () => {

      swiper.slideReset();

      swiper.update()
    };
  }

  return (
    <div id="productSlider" className="swiper-container products">
      <div className="swiper-wrapper">
        { loaded ?
            products.map( i => (
              <div className="swiper-slide" key={i.id}>
                <Product
                  menuId={menuId}
                  business={business}
                  product={i}/>
              </div>)):
            <div className="spinner"><BounceLoader loading={!loaded}/></div>
        }
      </div>
    </div>
  );
}

export default Products;

import Modal from 'components/Modal';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import './style.scss';

const allergensDictionary = [
    {id: "gluten", title:"Glutine", description:"cereali, grano, segale, orzo, avena, farro, kamut, inclusi ibridati e derivati"},
    {id: "shellfish", title:"Crostacei e derivati", description:"sia quelli marini che d’acqua dolce: gamberi, scampi, aragoste, granchi, paguri e simili"},
    {id: "egg", title:"Uova e derivati", description:"tutti i prodotti composti con uova, anche in parte minima. Tra le più comuni: maionese, frittata, emulsionanti, pasta all’uovo, biscotti e torte anche salate, gelati e creme ecc"},
    {id: "fish", title:"Pesce e derivati", description:"inclusi i derivati, cioè tutti quei prodotti alimentari che si compongono di pesce, anche se in piccole percentuali"},
    {id: "peanuts", title:"Arachidi e derivati", description:"snack confezionati, creme e condimenti in cui vi sia anche in piccole dosi"},
    {id: "soy", title:"Soia e derivati", description:"latte, tofu, spaghetti, etc."},
    {id: "milk", title:"Latte e derivati", description:"yogurt, biscotti e torte, gelato e creme varie. Ogni prodotto in cui viene usato il latte"},
    {id: "nuts", title:"Frutta a guscio e derivati", description:"tutti i prodotti che includono: mandorle, nocciole, noci comuni, noci di acagiù, noci pecan e del Brasile e Queensland, pistacchi"},
    {id: "celery", title:"Sedano e derivati", description:"presente in pezzi ma pure all’interno di preparati per zuppe, salse e concentrati vegetali"},
    {id: "mustard", title:"Senape e derivati", description:"si può trovare nelle salse e nei condimenti, specie nella mostarda"},
    {id: "sesame", title:"Semi di sesamo e derivati", description:"oltre ai semi interi usati per il pane, possiamo trovare tracce in alcuni tipi di farine"},
    {id: "so2", title:"Anidride solforosa e solfiti in concentrazioni superiori a 10 mg/kg o 10 mg/l espressi come SO2", description:"usati come conservanti, possiamo trovarli in: conserve di prodotti ittici, in cibi sott’aceto, sott’olio e in salamoia, nelle marmellate, nell’aceto, nei funghi secchi e nelle bibite analcoliche e succhi di frutta"},
    {id: "lupin", title:"Lupino e derivati", description:"presente ormai in molti cibi vegan, sotto forma di arrosti, salamini, farine e similari che hanno come base questo legume, ricco di proteine"},
    {id: "molluscs", title:"Molluschi e derivati", description:"canestrello, cannolicchio, capasanta, cuore, dattero di mare, fasolaro, garagolo, lumachino, cozza, murice, ostrica, patella, tartufo di mare, tellina e vongola etc."},
];

export default function Allergens()
{
    const {items} = useParams();

    const history = useHistory();

    return(
        <Modal>
            <table id="allergens__table">
                <thead>
                    <tr><th>Allergene</th><th>Esempi</th></tr>
                </thead>
                <tbody>
                    {allergensDictionary
                        .filter(i => items.search(i.id) !== -1)
                        .map(allergen => (
                        <tr key={allergen.id}><td>{allergen.title}</td><td>{allergen.description}</td></tr>
                    ))}
                </tbody>
            </table>
            <button 
                className="bottom button block outline"
                onClick={ () => history.goBack()}
            > CHIUDI</button>
        </Modal>
    )
}
import React, { useEffect, useState, useContext } from 'react';
import Swiper from 'swiper';
import { BusinessContext } from '../../../providers/BusinessProvider';
import {filterEvaluation} from "../../../helpers/menuFilters";
import {firestore} from "firebase";
import {CALENDAR_DATE_FORMAT} from "../../../config";
import dayjs from "dayjs";
import {CartContext} from "../../../providers/CartProvider";



const db = window.firebase.firestore();


function Menus({onChange}) {

  const [menus, setMenus] = useState([]);
  const [currentMenu, setCurrentMenu] = useState(null);
  const business = useContext(BusinessContext)
  const {cleanProductWithUnActiveMenu} = useContext(CartContext)


  useEffect(loadSlider, [menus])

  useEffect(() => {
    cleanProductWithUnActiveMenu(menus.map(i => i.id))
  }, [menus])


  useEffect(loadMenus, [business.id])

  /*
    Selezione automatica in base al
  */

  function loadMenus()
  {
    if(!business.id) return ;

    const menusPlaceholder = [];

    //TODO: Verificare se c'è possibilità di recuperare solo id interessati nel caso di gruppi
    db.collection(`business/${business.id}/menus`).get()
    .then(menuSnapshot => {
      if(menuSnapshot.empty)
      return setMenus([]);

      menuSnapshot.forEach((doc) => {
         if(business.qrCode?.menus && business.qrCode.menus.length > 0 && !business.qrCode.menus.includes(doc.id))
         {
              return ;
         }

         menusPlaceholder.push({...doc.data(), id: doc.id})
      });

      const sortedMenus = menusPlaceholder.sort((i,j) => i.order > j.order ? 1 : -1);

      firstSelectionPolicy(sortedMenus);
    })

  }

  //FILTRI
  /*
      Data, Ora, Giorno Settimana
   */


  /**
   *  automaticMenuSelection: Selezione automatica in base all'orario
   *  hideUnactiveMenu: nasconde i menu non attivi
   */
  function firstSelectionPolicy(sortedMenus)
  {
    if(!business.automaticMenuSelection && !business.hideUnactiveMenu)
    {
      selectMenu(sortedMenus[0].id);
      return setMenus(sortedMenus);
    }

    let menuFilterForGroupIfAny = sortedMenus
    if(business.reservation)
    {
      const activeMenuForGroupAndDay = business.reservation?.calendar[dayjs().format(CALENDAR_DATE_FORMAT)]
          .reduce((acc, i) => {
            if(i?.courses > 0) acc.push(i.menuId)

            return acc;
          }, [])

      menuFilterForGroupIfAny = menuFilterForGroupIfAny.filter( i => activeMenuForGroupAndDay.includes(i.id))
    }

    const activeMenu = menuFilterForGroupIfAny.filter(({filters, active}) => filterEvaluation(filters, active));


    //TODO: Se non ci sono menu attivi mostrare tutti i prodotti di default?
    if(activeMenu.length  > 0)
      selectMenu(activeMenu[0].id);

    if(business.hideUnactiveMenu)
      setMenus(activeMenu)

  }



  function loadSlider()
  {
    new Swiper ('#menuSlider.swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 20,
      preventClicksPropagation: false,
      slideToClickedSlide: true,
    })
  }


  function selectMenu(id)
  {

    setCurrentMenu(id);
    onChange(id);
  }

  return (
    <>
     { menus.length > 1 && (<>
        <h2 className="section_title">Menu</h2>
        <div id="menuSlider" className="swiper-container menus">
          <div className="swiper-wrapper">
            {  menus.map(i => {
              return <Menu key={i.id} onClick={selectMenu} {...i} isSelected={i.id === currentMenu}/>
            })}
          </div>
        </div>
        </>
     )
      }
    </>
  );
}

function Menu({name, id, onClick, isSelected}) {
  return (
    <div className={`swiper-slide menu ${isSelected && 'menu__active'}`}>
      <button className="menu_title" onClick={() => onClick(id)}>{name}</button>
    </div>
  )
}


export default Menus;

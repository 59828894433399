import React, { useState, useEffect, useCallback } from "react";
import Alert from '../../../components/Alert';
import debounce from 'lodash/debounce';
import { DEFAULT_USER } from 'providers/CartProvider'




function SearchLocationInput({ value, polygon, onChange, cart })
{
  const [query, setQuery] = useState(value.formatted_address);
  const [textChanged, setTextChanged] = useState(false);
  const autocompleteService = new window.google.maps.places.AutocompleteService();
  const [predictions, setPredictions] = React.useState([]);
  const [error, setError] = useState(false);
  const [placeService, setPlaceService] = React.useState();


  const handleSearch = (query, textChanged) =>
  {

    if (!query || query.length < 5 || !textChanged)
      return;

    autocompleteService.getPlacePredictions(
      {
        input: query,
        componentRestrictions: {
          country: "IT", // country: string | string[];
        },
      }, handleSuggestions);
    return

  }

  const debounceSearch = useCallback(debounce(handleSearch, 800), []);

  React.useEffect(() => debounceSearch(query,textChanged), [query])


  React.useEffect(() =>
  {

    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    setPlaceService(service);

  }, [])




  useEffect(() =>
  {
    if (!polygon || autocompleteService) return;

  }, [polygon]);


  function isWithinPoly(container, point)
  {
    const polygon = new window.google.maps.Polygon({ paths: container });
    return window.google.maps.geometry.poly.containsLocation(point, polygon);
  }


  function onInputChange({ target })
  {
    if (target?.value === undefined)
      return onChange(DEFAULT_USER.address)
    setTextChanged(true);
    setQuery(target.value);
    debounceSearch();
  }



  const handleSuggestions = (predictions, status) =>
  {
    if (status != window.google.maps.places.PlacesServiceStatus.OK)
    {
      setPredictions([]);
      return;
    }
    setPredictions(predictions);
  }


  function onPlaceSelect(placeId)
  {
    setTextChanged(false);
    placeService.getDetails({ placeId }, (place, status) =>
    {

      const { location } = place.geometry;

      if (!place.address_components.find(item => item.types.includes('street_number')))
      {
        onChange({ ...value, formatted_address: place.formatted_address, lat: '', lng: '' })
        setQuery(place.formatted_address);
        setError({ type: 'error', text: 'Seleziona il numero civico' });
        return false;
      }

      if (!isWithinPoly(polygon, new window.google.maps.LatLng(location.lat(), location.lng())))
      {

        setQuery('');
        setPredictions([]);
        setError({ type: 'error', text: 'Ci dispiace, ma l\'indirizzo selezionato è fuori dalla nostra area di consegna.' });
        onChange(DEFAULT_USER.address);
        return false;
      }

      setQuery(place.formatted_address);
      setError(false);
      setPredictions([]);

      onChange({
        formatted_address: place.formatted_address,
        street: place.address_components.find(i => i.types.includes('route'))?.long_name,
        city: place.address_components.find(i => i.types.includes('locality'))?.long_name,
        zip: place.address_components.find(i => i.types.includes('postal_code'))?.long_name,
        number: place.address_components.find(i => i.types.includes('street_number'))?.long_name,
        lat: location.lat(),
        lng: location.lng()
      });
    })

  }


  return (
    <div style={{ width: '100%' }}>
      <input
        className={cart?.error?.validation?.address_street && 'error'}
        onChange={onInputChange}
        placeholder="Inserisci un indirizzo"
        value={query}
      />
      {predictions.length ? <ul className="autocomplete-ul">
        {predictions.map((p) => (
          <li
            className="autocomplete-li"
            key={p.place_id}
            onClick={() => onPlaceSelect(p.place_id)}
          >
            {p.description}

          </li>
        ))}
      </ul> : null}
      <Alert message={error} />
    </div>
  );
}

export default SearchLocationInput;

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {motion} from 'framer-motion'

import './style.scss'

import { SLIDE_IN } from 'config';
import useLocalStorage from 'helpers/useLocalStorage';


const Orders = () => {  

    const history = useHistory();
    const { businessId } = useParams();

    const [orderList] = useLocalStorage("orders", []);

   function goTo(order)
   {
       history.push({
        pathname: `/${businessId}/order`,
        search: history.location.search+`&orderId=${order.id}&orderToken=${order.token}`
       })
   }

   function close()
   {
       history.push({
        pathname: `/${businessId}`,
        search: history.location.search 
       })
   }

    return(
        <motion.div
            variants={SLIDE_IN}
            initial="start"
            animate="mid"
            exit="end"
            id="orders"
            className="fullscreen-top"
        >
            <div className="fullflex content">
                <h1 className="title">I Tuoi Ordini</h1>
                <ul className="orders">
                    {
                        orderList.reverse().map(order => (
                            <li 
                                className="orders__order"
                                key={order.id}
                                onClick={() => goTo(order)}>
                                    <div className="orders__order__left">
                                        
                                        <span className={`orders__order__icon fe-icon-${order.type}`} />
                                        
                                        <div className="orders__order__text">
                                            Ordine di {order.total} € con {order.products.length}&nbsp;
                                            prodott{order.products.length > 1 ? 'i' : 'o'}.
                                            <span>{order.date? order.date : (new Date(order.createdAt._seconds * 1000)).toLocaleString('it')} </span>
                                        </div>
                                    </div>
                                    <div className="orders__order__arrow">
                                        &gt;
                                    </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
            

            <button         
                className={`bottom button block outline`} 
                onClick={close}>
                CHIUDI
            </button>
        </motion.div>
    )
   
  };

export default Orders;
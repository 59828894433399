import React from 'react';
import { motion } from 'framer-motion'
import { FADE_IN } from 'config';

export default function OpenCartPageWrapper({children})
{
    return (
        <motion.div     
            className="cart__resume"
            variants={FADE_IN} 
            initial="start"
            animate="end"
            exit="start">
                {children}
        </motion.div>
    )
}
import React, {useState, useEffect} from 'react';

import PaiazoCheckbox from '../../../components/PaiazoCheckbox'

const IngredientsList = ({ product, onChange }) => {  


    const [ingredients, setIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    

    useEffect(() => {
        const ingredientsToAdd = Array.isArray(product.ingredients) ? product.ingredients : []
        setIngredients(ingredientsToAdd);

    }, [product]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onChange(selectedIngredients), [selectedIngredients])
 
 

    function selectIngredients(ingredient)
    {
        const beforeLength = selectedIngredients.length;

        const removedIngredientArray = selectedIngredients.filter(i=> i.id !== ingredient.id)

        if(beforeLength !== removedIngredientArray.length)
            return setSelectedIngredients(removedIngredientArray)

        if(product.maxIngredients > 0 && beforeLength >= product.maxIngredients) return; //TODO: Toast di avviso

        setSelectedIngredients([...selectedIngredients, ingredient])
    }

    function formatPrice(price)
    {
        if(!price) return '';
        if(price === 0) return '';

        return ` € ${price.toFixed(2).replace('.',',')}`;
    }


    return (
        
        <div id="add2cart__ingredients">
            <label>Componi il tuo ordine</label> <br/>
            {product.maxIngredients > 0 && `Puoi selezionare al massimo ${product.maxIngredients} elementi`}
                <ul>
                    {ingredients.map( i => (
                        <li key={i.id}>
                            <PaiazoCheckbox checked={selectedIngredients.find(e=> e.id ===i.id) !== undefined} id={i} onChange={selectIngredients}>
                                {`${i.name}${formatPrice(i?.price)}`}
                            </PaiazoCheckbox>
                        </li>
                    ))}
                </ul>
        </div>
);
  };

export default IngredientsList;
import React, { useEffect, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import './style.scss'

import {  useHistory } from 'react-router-dom';
import { useBusiness } from 'helpers/hooks';
import { Form, TextInput, Checkbox, Select } from 'components/Form/index';
import Button from 'components/Button';
import * as yup from "yup";

import { firebaseFunctions } from 'helpers/firebase';
import { DEFAULT_TRANSITION, FADE_IN } from 'config';
const schema = yup.object({
  firstName: yup.string().required('Nome è obbligatorio').label('Nome'),
  lastName: yup.string().required('Cognome è obbligatorio').label('Cognome'),
  telephone: yup.number('Inserire solo numeri').required().label('Telefono'),
  table: yup.string().required('Seleziona un tavolo').label('Tavolo'),
  email: yup.string().email('Non è una mail valida').required('Email obbligatoria').label('Email'),
  covid: yup.boolean().oneOf([true], 'Devi confermare la Dichiarazione No Covid').required().label('Dichiarazione No Covid'),
  privacy: yup.boolean().oneOf([true], 'Devi accettare la Privacy').required().label('Privacy'),
}).required();

function CovidForm() {
  const [isBusinessLoading, business] = useBusiness();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tables, setTables] = useState([]);
  const history = useHistory();

  useEffect(() =>
  {
    if(!business || !business.id) return

    if(!business.covidForm)
      return history.replace(`/${business.id}/`)


    loadTables();

  }, [business]);

  const loadTables = () => {
    if(tables.length > 0 ) return ;

    setLoading(true);
    firebaseFunctions().httpsCallable('getTables')({
      businessId: business.id,
    })
    .then(({data}) => {
      setTables(data.map(i => ({id: i.id, label: i.name})).sort((i,j) => i.label.localeCompare(j.label, undefined, {numeric: true, sensitivity: 'base'})));

    }).catch(e => console.error(e))
    .then(() => setLoading(false));
  }

  const submit = (data, reset, d) =>
  {

    if(loading) return;

      setLoading(true);
      firebaseFunctions().httpsCallable('covidForm')({
        ...data,
        table: tables.find(i => i.id == data.table),
        businessId: business.id,
      })
      .then(res => {

        setSuccess(true);
        reset()

      }).catch(e => console.error(e))
      .then(() => setLoading(false));

  }

  return (
    <AnimatePresence exitBeforeEnter>
          {!success ? (<motion.div
            variants={FADE_IN}
            transition={DEFAULT_TRANSITION}
            initial="start"
            animate="end"
            exit="start"
            key="form"
          >
            <h1>Registro presenze</h1>
              <p>
                I dati raccolti sono ad esclusivo utilizzo del registro presenze per finalità connesse all'emergenza sanitaria e verranno eliminati automaticamente dal sistema al 14esimo giorno.
                Nessun dato verrà condiviso o utilizzato a scopo di marketing.
              </p>

              <Form onSubmit={submit} validationSchema={schema}>
                <TextInput
                  name="firstName"
                  autoComplete="given-name"
                  placeholder="Nome"/>

                <TextInput
                  name="lastName"
                  autoComplete="family-name"
                  placeholder="Cognome"/>

                <TextInput
                  name="telephone"
                  autoComplete="tel"
                  placeholder="Telefono"/>

                <TextInput
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email"/>

                <Select
                  placeholder='Seleziona il tuo tavolo'
                  name="table"
                  options={tables}
                />

        {/*
                <PaiazoCheckbox
                        name="covid-box">
                        Salva i miei dati per future visite
                  </PaiazoCheckbox>
        */}
                <Checkbox
                name="covid"
                      >
                        Dichiaro di non essere affetto da COVID-19, di non presentare sintomi riconducibili a
        COVID-19 e di non essere sottoposto a misure di limitazione o a periodo di
        quarantena obbligatoria
                  </Checkbox>

                  <Checkbox name="privacy"
                        >
                          Dichiaro di aver preso visione e di accettare la&nbsp;
                            <a  target="_blank" href={`/${business?.id}/privacy`}>Privacy Policy</a>
                        </Checkbox>

                  <Button
                      disabled={loading}
                      loading={loading}
                      label={'Invia'}
                      />
              </Form>
        </motion.div> ) : (
        <motion.div
           variants={FADE_IN}
           transition={DEFAULT_TRANSITION}
           initial="start"
           animate="end"
           exit="start"
           key="success"
        >
          <div style={{
               height: '90vh',
               display: 'flex',
               flexDirection: 'column',
               flex: 1,
               alignItems: 'center',
               justifyContent: 'center',
              width: '100%'
              }}>
            <h2>
            Form inviato con successo!
            </h2>
            <div style={{ width: '100%'}}>
              <Button label={'Nuova registrazione'} onClick={() => setSuccess(false)}/>
              <Button label={'Vai allo shop'} onClick={() => history.push(`/${business.id}`)}/>
            </div>
          </div>
        </motion.div>
        )}
      </AnimatePresence>
  );
}

export default CovidForm;




import React, { useState, useContext, useEffect, useMemo } from 'react';

import Menus from './components/Menus';
import Categories from './components/Categories';
import Products, { filterCondition } from './components/Products';

import ModalSwitch from '../../ModalSwitch'
import { BusinessContext } from '../../providers/BusinessProvider';

import './style.scss';
import HamburgerIcon from 'components/HamburgerIcon';
import useLocalStorage from 'helpers/useLocalStorage';
import { useHistory } from 'react-router-dom';
import { ShopContext } from "../../providers/ShopProvider";
import { CartContext } from "../../providers/CartProvider";

const db = window.firebase.firestore();

function Home()
{

  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [category, setCategory] = useState()

  const [orders] = useLocalStorage("orders", [])

  const history = useHistory()
  const business = useContext(BusinessContext)
  const shop = useContext(ShopContext)
  const cart = useContext(CartContext)

  const onCategoryChange = category => setCategory(category)

  const onMenuChange = menuId => shop.setMenu(menuId)

  useEffect(() =>
  {
    loadCategories();
    loadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business.id]);

  /*
  * Calcola il numero di prodotti per categoria
  */
  const categoriesWithProductNumbers = useMemo(
    () => categories.map(i =>
    {
      return {
        ...i,
        numberOfProducts: products.filter(j => filterCondition(j, categories, shop.menu, i.id, business)).length
      }
    }),
    [products, shop.menu, categories],
  );


  const filteredProducts = useMemo(
    () =>
    {
      if (products.length === 0) return [];

      const filteredProduct = products.filter(i => filterCondition(i, categories, shop.menu, category?.id, business));


      return filteredProduct
        .sort((a, b) =>
        {
          if (a.category_id === b.category_id)
            return a.order > b.order ? 1 : -1

          const catA = categories.find(cat => cat.id === a.category_id)
          const catB = categories.find(cat => cat.id === b.category_id)
          if (!catA || !catB) return 1;
          return catA.order > catB.order ? 1 : -1
        });
      //.sort((a, b) => a['order'] - b['order']);
    },
    [products, category, categories, shop.menu],
  );


  if (business.disabled)
    return (
      <div className="disabled">
        Servizio attualmente non disponibile.<br />
        Ci scusiamo per il disagio.
      </div>
    )
  const now_seconds = new Date().getTime() / 1000;
  if (business.expiration && business.expiration.seconds < now_seconds)
    return (
      <div className="disabled">
        Servizio attualmente non disponibile.<br />
        Contatta l'amministrazione per rinnovarlo.
      </div>
    )

  function showOrders()
  {
    history.push({
      pathname: `/${business.id}/orders`,
      search: history.location.search
    })
  }

  async function loadCategories()
  {
    if (!business.id) return;

    const categoriesPlaceholder = [];

    if (!business?.disableAllProductsCategory === true)
      categoriesPlaceholder.push({ id: '', name: 'Tutti i prodotti', icon: 'cloche', order: '' })

    db.collection(`business/${business.id}/categories`).get()
      .then((querySnapshot) =>
      {
        querySnapshot.forEach((doc) =>
        {
          categoriesPlaceholder.push({ id: doc.id, ...doc.data() })
        });

        const sortedCategories = categoriesPlaceholder
          .sort((i, j) => j.order === '' ? 1 : i.order > j.order ? 1 : -1);

        if (!business?.disableAllProductsCategory === true)
          sortedCategories[0]['numberOfProducts'] = sortedCategories.reduce((total, i) => i.numberOfProducts ? total + i.numberOfProducts : total, 0)

        setCategories(sortedCategories);
      });
  }

  async function loadProducts()
  {
    if (!business.id || products.length > 0) return;

    db.collection(`business/${business.id}/products`).get()
      .then((querySnapshot) =>
      {

        const allProducts = [];
        querySnapshot.forEach((doc) =>
        {

          if (doc.data()?.active !== false)
            allProducts.push({ id: doc.id, ...doc.data() })
        });
        setProducts(allProducts);
      });
  }

  return <div>
    <ModalSwitch />
    <h1 className="title">{business.frontendLogo && <img src={business.frontendLogo} alt="logo" />}
      {business.name}
      {orders && orders.length > 0 && <HamburgerIcon onClick={showOrders} />}
    </h1>
    {cart.type !== 'table' && business.delivery && business.delivery.active &&
      <span id="delivery-area__link" onClick={() => history.push({
        pathname: `/${business.id}/delivery-area`,
        search: history.location.search
      })}>Visualizza Area di Consegna</span>
    }
    <Menus onChange={onMenuChange} />
    <h2 className="section_title">Categoria</h2>
    <Categories categories={categoriesWithProductNumbers} menu={shop.menu} onChange={onCategoryChange} />
    <h2 className="section_title">Prodotti</h2>
    <Products products={filteredProducts} menuId={shop.menu} />
  </div>
}

export default Home;

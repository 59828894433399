
import React from 'react';

import { motion } from "framer-motion"
import { FADE_IN } from 'config';


const DummyMotion = () => {
    
    return (
        <motion.div  
            transition={{ duration: 0 }}
            variants={FADE_IN}        
            initial="start" 
            animate="end" 
            exit="start" />
     )
}
export default DummyMotion;
import React, { useContext } from 'react';

import { CartContext } from 'providers/CartProvider';

const CartError = () => {

    const cart = useContext(CartContext);

    return (
        <>
        { cart?.error && 
            <div className="error alert">
                {cart.error.message}
                <ul>
                    {Object.keys(cart.error?.validation||[]).map(i => (
                        <li key={i}>{cart.error.validation[i]}</li>
                    ))}
                </ul>
            </div> 
        }
        </>
    )

}

export default CartError;
import React, { useContext, useState } from 'react';
import {formatPrice} from 'helpers';


import { CartContext } from 'providers/CartProvider';
import CartError from '../components/CartError';
import CartUserInfo from '../components/CartUserInfo';
import PaymentMethods from '../components/PaymentMethods';
import Paypal from '../components/Paypal';

import { BusinessContext } from 'providers/BusinessProvider';
import Button from 'components/Button';
import OpenCartPageWrapper from '../components/OpenCartPageWrapper';

const Checkout = () => {

  const [loading, setLoading] = useState(false);

  const cart = useContext(CartContext);
  const business = useContext(BusinessContext);
  const minOrderRespected = (cart.type === 'delivery' && cart.totalPrice >= cart.minOrderForDelivery) || cart.type !== 'delivery';

  async function submit()
  {
    if(loading) return;
    
    setLoading(true);    
    await cart.placeOrder();
    setLoading(false);    
  }

  return (
    <OpenCartPageWrapper>
        <h2 className="section_title">Completa Ordine</h2>

        <CartError/>

        <CartUserInfo
            business={business}
        />
        <PaymentMethods 
          value={cart.payment}
          onChange={value => cart.setPayment(value)}
          business={business}/>

        
        {cart.payment !== 'paypal' || !cart.isShopOpen || !minOrderRespected
         ?
            <Button 
              disabled={!cart.isShopOpen || !minOrderRespected}
              loading={loading}
              onClick={submit}
              label={!minOrderRespected ? `Ordine minimo per la consegna ${formatPrice(cart.minOrderForDelivery)}` : cart.payment === 'cash' ? `COMPLETA ORDINE` : `PAGA ${formatPrice(cart.totalPriceWithDelivery)}` }
              /> :
              <Paypal business={business}/>
        }
      </OpenCartPageWrapper>
  );
};

export default Checkout;

import React, {useMemo, useState} from 'react';

function ShopProvider(props)
{
    const [group, setGroup] = useState(false);
    const [menu, setMenu] = useState();


    const stateToPublic = useMemo(() => ({
        group,
        setGroup,
        menu,
        setMenu
    }), [
        group,
        setGroup,
        menu,
        setMenu
    ]);

    return (
        <ShopContext.Provider value={stateToPublic}>
            {props.children}
        </ShopContext.Provider>
    );
}

export default ShopProvider;

const ShopContext = React.createContext({});

export { ShopContext }

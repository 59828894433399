import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import marked from 'marked';
import DOMPurify from 'dompurify';
import { motion, usePresence } from 'framer-motion';

import '../scss/productCard.scss';
import {formatPrice} from 'helpers';

import { FULLPAGE_PRODUCT_TRANSITION_TIME_MS } from '../config'
import Allergens from './ProductCard/Allergens';


const EXPANDED_PRODUCT_SUFFIX = '_exp'

//Per risolvere bug che al cambio di switch attivo il Modal
// rimane montato bloccando tutti i click
function hideModal()
{
    const modals = window.document.getElementsByClassName('expanded-product');

    if(modals.length > 0){
      setTimeout(() => {if(modals[0]) modals[0].classList = modals[0].classList+' hide'}, 300)
    }
}
function removeHideClass()
{
    const modals = window.document.getElementsByClassName('expanded-product hide');

    if(modals.length > 0)
    {
        modals[0].classList = modals[0].classList.value.replace('hide', '')
    }
}

function Product({product, business, isExpanded, menuId}) {

    const {name, allergensArray, image, description, long_description, price, id, category_id} = product;

    const history = useHistory();

    const selectedMenuLimit = business?.reservation && business.reservation.menuLimits.find(i => i.id === menuId);
    const limit = (selectedMenuLimit?.categories||[]).find(i => i.id === category_id)?.limit

    const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    removeHideClass()

    if(!isPresent)
    {
      hideModal()
      safeToRemove();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent])

  /*
  * Per pagina prodotto full page, blocco lo scroll del body
  */
  useEffect(()=> {
    if(isExpanded)
      setTimeout(() => {
        document.body.style.maxHeight = '90vh';
        document.body.style.overflow = 'hidden';
        document.getElementById(`prod_${id}${EXPANDED_PRODUCT_SUFFIX}`)
                .getElementsByClassName("card__image")[0].style.borderRadius = '0';
      }, FULLPAGE_PRODUCT_TRANSITION_TIME_MS);

    return () => {
      document.body.style.maxHeight = null;
      document.body.style.overflow = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function addToCartModal()
  {
    history.push(
      `/${business.id}/addToCart/${product.id}` + history.location.search,
      {product: product}
    );
  }

  function openFullPage(e)
  {
    if(isExpanded || !long_description || e.target.className === 'allergen')
      return ;

    history.push({
      pathname: `/${business.id}/product`,
      search: history.location.search,
      state: { product, element_id: `prod_${id}` }
    });
    return ;

    // const currentSwiperClass = e.currentTarget.parentElement.className

    // console.log(currentSwiperClass, currentSwiperClass.search("slide-active"));

    // if(currentSwiperClass.search("slide-active") === -1)
    //   return ;

  }

  function closeFullPage()
  {
    //Aggiungo classe per togliere il wrap dal titolo
    document.getElementById(`prod_${id}${EXPANDED_PRODUCT_SUFFIX}`)
      .classList.add('nowrap');

    history.push({
      pathname: `/${business.id}`,
      search: history.location.search
    });
  }


  return (
    <div
      id={`prod_${id}${isExpanded ? EXPANDED_PRODUCT_SUFFIX : ''}`}
      className="card card--big">
      {isExpanded ? (
         <motion.div
         variants={imageHeigth}
         initial="start"
         animate="end"
         exit="start"
            className="card__image"
            style={{
              backgroundImage: image ? `url(${image})` : 'url(/foodPlaceholder.png)'
            }}>
              <img alt="chiudi" src="/close.svg" className="close-icon" onClick={closeFullPage}/>
          </motion.div>
      ): (
          <div
            onClick={openFullPage}
            className="card__image swiper-lazy"
            data-background={`${image ? image : '/foodPlaceholder.png'}`}>
            {/* TODO: mettere loader uguale agli altri */}
                <div className="swiper-lazy-preloader"></div>
          </div>
          )
      }

        <div className="card__text"  onClick={openFullPage}>
            <h2 className="card__title">{name}</h2>
            {!isExpanded &&
              <p>{description}</p>}
            { long_description &&
              !isExpanded &&
               <button
                className="card__more"
                onClick={openFullPage}>
                 Scopri i dettagli
                </button>
            }
            {isExpanded &&
              <motion.p
                variants={longDescriptionVariants}
                initial="start"
                animate="end"
                exit="start"
                className="card__long-description"
                transition={{duration: .2}}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(marked(long_description, {breaks: true}), {ALLOWED_TAGS: ['strong', 'ul', 'li', 'ol', 'a', 'h1', 'h2', 'h3', 'br', 'u', 'i', 'em', 'br']})
                }}
              />
            }
            <motion.div
                variants={longDescriptionVariants}
                style={{flexGrow: 1}}
                initial="start"
                animate="end"
                exit="start">
            <Allergens
              businessId={business.id}
              items={allergensArray}
            />
            </motion.div>


        </div>
        <div className="card__action-bar">
            <div className="card__action-bar_price">
                {
                    limit > 0 ? '' : formatPrice(price)
                }
            </div>
            {
              business?.allowOrder ? (
                <button onClick={addToCartModal} id={`addToCart__${id}`} className="card__action-bar_addToCart">
                  <svg viewBox="0 0 24 24">
                      <path  d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                </button>
              ) : null
            }

        </div>
    </div>
  );
}

export default Product;

const longDescriptionVariants = {
  start:{ opacity: 0, scale: 0, display: "none"},
  end:{ opacity: 1, scale: 1, display: "block"},
}

const imageHeigth = {
  start:{ height: "14rem"},
  end:{ height: "18rem"},
}


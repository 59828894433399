import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {WEEKDAYS} from "../config";

dayjs.extend(customParseFormat)

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const today = dayjs();

export const filterEvaluation = (filters, active) => {

    if(!active) return false;
    if(!filters) return true;

    let lastCondition = true;
    for(let i = 0; i < filters.length; i++)
    {
        const hasPassedQuery = filters[i].children.find(i => filterFactory(i.type, i.value) === false) === undefined
        lastCondition = operators[filters[i].value]([lastCondition, hasPassedQuery])
    }

    return lastCondition;
}

export const filterFactory = (type, value) =>
{
    return filters[type](value)
}

const filters = {
    date: value => today.isSameOrAfter(dateToDayJs(value.from)) &&
        today.isSameOrBefore(dateToDayJs(value.to).hour(23).minute(59).second(59)),
    hour: value => today.format('HH:mm') >= value.from && today.format('HH:mm') <= value.to,
    weekDay: value => {
        try{
            return value[WEEKDAYS[today.day()]] === true
        } catch(e) {
            return false;
        }
    }
}

const operators = {
    and: (conditions) => conditions.reduce((i, acc) => acc = acc && i, true),
    or: (conditions) => {
        const res = conditions.reduce((i, acc) => acc = acc || i, null)
        return res === null ? false : res;
    }
}

const dateToDayJs = (date) => dayjs(date, 'DD/MM/YYYY')


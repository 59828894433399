import React from 'react';

const HamburgerIcon = ({onClick}) => (
    <button className="hamburger" type="button" onClick={onClick}>
        <span className="hamburger-box">
        <span className="hamburger-inner"></span>
        </span>
    </button>
)

export default HamburgerIcon;
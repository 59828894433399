import React, {useContext, useState, useEffect} from 'react';
import { BusinessContext } from '../../providers/BusinessProvider';
import PaymentMethodsFactory from 'helpers/PaymentMethodsFactory';

import { useHistory, useLocation } from 'react-router-dom';
import {motion} from 'framer-motion'

import './style.scss'
import { BounceLoader } from 'react-spinners';
import { firebaseFunctions } from 'helpers/firebase';

import { SLIDE_IN } from 'config';
import Alert from 'components/Alert';
import { getReference } from 'helpers/index';

const deliveryStatusDictionary = {
    'waiting': 'In attesa di conferma',
    'accepted': 'Confermato',
    'changeProposal': 'Richiesta cambio orario',
    'refused': 'Annullato - Purtroppo non abbiamo disponibilità nella fascia oraria selezionata',
    'canceled': 'Annullato'

}


const Order = () => {  

    const business = useContext(BusinessContext);
    const history = useHistory();
    const location = useLocation();
    
    const [loading, setLoading] = useState(false);
    const [loadingPay, setLoadingPay] = useState(false);
    const [message, setMessage] = useState(false);
    const [order, setOrder] = useState(false);
    const queryParams = (new URLSearchParams(location.search));
    const orderId = queryParams.get('orderId');
    const orderToken = queryParams.get('orderToken');

    //
    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add("noscroll");
    
        return () => {
            document.getElementsByTagName('body')[0].classList.remove("noscroll");
        }
    }, []);

    useEffect(() => {
        if(order) return;


        if(!orderId || !orderToken)
            return setMessage({text:'Impossibile caricare ordine.', type:'error'})

        if(business.id)
            loadOrder(orderId, orderToken)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [business])

    async function loadOrder(orderId, orderToken)
    {        
        setLoading(true);

        const getOrder = firebaseFunctions().httpsCallable('getOrder');

        return getOrder({
                businessId: business.id,
                orderId: orderId,
                orderToken: orderToken
            })
            .then(async ({data}) => setOrder(data))
            .catch(error => {
                console.error('failed', error);
                setMessage({text: 'Impossibile caricare l\'ordine.', type: 'error'})
                return;
            })
            .then(() => setLoading(false));        
    }


    async function changeProposalAction(type)
    {        
        setLoading(true);
        setMessage(false);

        const callableFunctionName = type === 'accept' ? 'confirmOrder' : 'cancelOrder'

        const changeStatus = firebaseFunctions().httpsCallable(callableFunctionName);

        return changeStatus({
                businessId: business.id,
                orderId: orderId,
                orderToken: orderToken
            })
            .then(({data}) => {
                setOrder({
                    ...order,
                    [order.type]: {
                        ...order[type],
                        status: data
                    }
                })

                setMessage({text:'Operazione completata con successo', type:'success'})

            })
            .catch(error => {
                console.error('Change Proposal failed', error);
                setMessage({text: error.message, type: 'error'})
                return;
            })
            .then(() => setLoading(false));        
    }


    async function pay()
    { 
        if(loadingPay) return;

        setLoadingPay(true);
        const paymentFactory = await new (await PaymentMethodsFactory(order.payment.type)).default(business)
        
        const payOrder = window.firebase.app().functions('europe-west1').httpsCallable('payOrder');
        const queryParams = new URLSearchParams(location.search);

        const orderData = {
            qrCodeId: queryParams.get("reference"),
            payment_type: order.payment.type,
            orderId: orderId,
            orderToken: orderToken,
            businessId: business.id,
            host: window.location.protocol + '//' + window.location.host
        }
       
        paymentFactory.beforeSubmit();

        return payOrder(orderData)
            .then(async ({data}) => 
                {
                    await paymentFactory.afterSubmit(data);       
                })
            .catch(error => {
                console.error('failed', error);
                setMessage({text: error.message, type: 'error'})
                return;
            })
            .then(() => setLoadingPay(false));        
      
    }

    function goBack()
    {
        history.push({
            pathname: `/${business.id}/orders`,
            search: getReference(location.search) 
           })
    }

    return(
        <motion.div
            variants={SLIDE_IN}
            initial="start"
            animate="mid"
            exit="end"
            id="order"
        >
            <div className="fullflex">
                <h1 className="title">Il Tuo Ordine</h1>
                <Alert message={message}/>

                {loading &&
                <div className="spinner">
                    <BounceLoader loading={true}/>
                    <span>Caricamento</span>
                </div>
                
                }
                {!loading && order && 
                (
                    <div>       
                        {order.type === 'delivery' && 
                            <div>
                                <h2 className="section_title">Consegna a Domicilio</h2>
                                <ul className="order_userInfo">
                                    <UserInfo value={`${order.user?.address.street}, ${order.user?.address.number} - ${order.user?.address.city}`} field="Indirizzo di consegna" />
                                    <UserInfo value={order.delivery?.date} field="Giorno di consegna" />
                                    <UserInfo value={order.delivery?.time} field="Orario di consegna" />
                                    <UserInfo value={deliveryStatusDictionary[order.delivery.status]} field="Stato" />
                                </ul>
                            </div>
                        }

                        {order.type === 'takeaway' && 
                            <div>
                                <h2 className="section_title">Asporto</h2>
                                <ul className="order_userInfo">
                                    <UserInfo value={order.takeaway?.date} field="Giorno di ritiro" />
                                    <UserInfo value={order.takeaway?.time} field="Orario di ritiro" />
                                    <UserInfo value={deliveryStatusDictionary[order.takeaway.status]} field="Stato" />
                                </ul>
                            </div>
                        }

                        {(
                            order?.delivery?.status === 'changeProposal' ||
                            order?.takeaway?.status === 'changeProposal') &&
                        <div className="order__changeProposal">
                            <p>
                                Non possiamo evadere il tuo ordine nell'orario da te indicato, 
                                ma abbiamo disponibilità per le ore <b>{order.delivery?.time||order.takeaway?.time}</b>.
                                <br/>Scegli cosa fare                                    
                            </p>  
                            <p className="text-center">
                                <button 
                                    className="button success"
                                    onClick={() => changeProposalAction('accept')}
                                >Accetta</button>
                                <button 
                                    className="button error"
                                    onClick={() => changeProposalAction('refuse')}
                                >Rifiuta</button>
                                </p>  
                        </div>}
                        
                        <h2 className="section_title">Informazioni</h2>
                        <ul className="order_userInfo">
                            <UserInfo value={order.user.name} field="Nome" />
                            <UserInfo value={order.user.telephone} field="Telefono" />
                            <UserInfo value={order.user.email} field="Email" />
                        </ul>

                        <h2 className="section_title">Prodotti</h2>
                        <table className="order__products" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr className="order__products-title">
                                    <td>Nome</td>
                                    <td className="text-right">Qta</td>
                                    <td className="text-right">Prezzo</td>
                                </tr>
                            </thead>
                            <tbody>
                            {order.products.map(
                                (product, idx) => (
                                <tr key={idx}>
                                    <td>{product.name}</td>
                                    <td className="text-right">{product.qty}</td>
                                    <td className="text-right">{product.price} €</td>
                                </tr>
                                )
                            )}
                            </tbody>
                            <tfoot>
                                {order.type==='delivery' &&
                                <tr className="order__products-footer">
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                        <small>Costi Consegna &nbsp;
                                        {order.delivery.cost} €
                                        </small>
                                    </td>
                                </tr>}
                                <tr className="order__products-footer">
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                        <small><b>Totale</b> </small>
                                        {order.total} €
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        {order.payment && order.payment.type !== 'cash'  && 
                            order.payment.status !== 'canceled'  && 
                            (<div>
                                <h2 className="section_title">Pagamento</h2>
                                {order.payment.status === 'pending' && (
                                    <p>L'ordine non risulta pagato</p> 
                                )}
                                {order.payment.status === 'authorized' &&
                                    <p>
                                        Pre-autorizzato<br/>
                                        <small>
                                            Abbiamo bloccato la cifra relativa al totale dell'ordine, 
                                            l'ammontare verrà prelevato solo una volta confermato l'ordine.
                                            In caso di annullamento la somma sarà immediatamente liberata.                                            
                                        </small>
                                    </p>
                                }
                                {order.payment.status === 'paid' &&
                                    <p>Ordine pagato con successo!</p>
                                }
                        </div>
                        )}
                    </div>
                )
                }


                <button         
                    className="button bottom block outline"
                    onClick={goBack}>
                    TORNA INDIETRO
                </button>
            </div>
        </motion.div>
    )
   
  };

export default Order;


const UserInfo = ({value, field}) => {

    if(value)
        return ( 
                 <li>
                    <span>{field}</span>
                    {value}
                </li>
             )

    return null;
}

                          
/*  <button 
    className={`order__button button block ${loadingPay ? 'loading' : ''}`} 
    onClick={pay}>
        { 
        loadingPay ? 
            <div className="spinner"><BounceLoader size={32} /></div> : 
            `PAGA ${order.total}€` 
        }
    </button> */
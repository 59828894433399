import React, { useEffect } from 'react';
import { motion, usePresence } from 'framer-motion';
import { DEFAULT_TRANSITION } from '../config'

//Per risolvere bug che al cambio di switch attivo il Modal
// rimane montato bloccando tutti i click
function hideModal()
{
    const modals = window.document.getElementsByClassName('modal');
    
    if(modals.length > 0){
      setTimeout(() => modals[0].classList = modals[0].classList+' hide', 300)
    }
}
function removeHideClass()
{
    const modals = window.document.getElementsByClassName('modal hide');

    if(modals.length > 0)
    {
        modals[0].classList = modals[0].classList.value.replace('hide', '')
    }
}

const Modal = ({children, className='' }) => {  
  const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    removeHideClass()
    
    if(!isPresent)
    {
      hideModal()
      safeToRemove();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent])

   

  return (
    <motion.div
      variants={modalBGVariant}
      initial="start"
      animate="end"
      exit="start"
      transition={DEFAULT_TRANSITION}
      className={`modal ${className}`}>
      <motion.div 
        variants={variantsModal}
        inital="hidden"
        animate="visible"
        exit="hidden"
        className="modal-main">
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Modal;

const modalBGVariant = {
  start:{ opacity: 0},
  end:{opacity: 1},
}

const variantsModal = {
  hidden:{  zoom: 0},
  visible:{ zoom: 1},
  transition:  {
    duration: .1
  } 
}
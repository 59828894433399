import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { FADE_IN } from 'config';
import PaymentMethodsFactory from 'helpers/PaymentMethodsFactory';

import SuccessIcon from 'components/SuccessIcon';
import WaitingIcon from 'components/WaitingIcon';
import { BusinessContext } from 'providers/BusinessProvider';
import { CartContext } from 'providers/CartProvider';
import useLocalStorage from 'helpers/useLocalStorage';
import OpenCartPageWrapper from '../components/OpenCartPageWrapper';


function CartSuccess() 
{
  const location = useLocation();
  const [status, setStatus] = useState('waiting');
  const [order, setOrder] = useState({});
  const business = useContext(BusinessContext);
  const cart = useContext(CartContext);

  const history = useHistory();

  const [orders, storeOrders] = useLocalStorage("orders", []);
      
  const queryParams = (new URLSearchParams(location.search));
  const sessionId = queryParams.get('sessionId');      
  const orderId = queryParams.get('orderId');
  const orderToken = queryParams.get('orderToken');
  const paymentType = queryParams.get('type');

  useEffect(() => {
    async function result() {
      if(!business.id) return;

      if(!paymentType || !orderId || !orderToken)
        return setStatus('error')

      const paymentFactory = await new (await PaymentMethodsFactory(paymentType)).default(business)

      paymentFactory.success(orderId, orderToken, sessionId)
        .then(({data}) => {      
          setStatus(data.payment.status);       
          setOrder(data)
          storeOrder({              
            id: orderId,
            token: orderToken,
            ...data
          });
    
          cart.reset();
        })
        .catch(err => {                        
          setStatus('error');  
      });
    }
    
    result();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  function storeOrder(order)
  {    
      const isAlreadStored = orders.find(i => i.id === orderId);

      if(isAlreadStored) return;

      storeOrders(
        orders.concat(order)      
      )
  }


  function goToOrder()
  {
      history.push({
        pathname: `/${business.id}/order`,
        search: history.location.search,
        state: {order: {id: orderId, token: orderToken}}
      })
  }

  return (   
    <OpenCartPageWrapper>

      <AnimatePresence>
        { (status === 'paid' ||  status === 'authorized') &&
          <motion.div 
            className="cart__resume cart__resume-success"
            variants={FADE_IN} 
            initial="start"
            animate="end" >
              <SuccessIcon/>
             
              { 
                order.type === 'table' ||
                order?.takeaway?.status === 'accepted' || 
                order?.delivery?.status === 'accepted' ?
                  <div>
                    <h2>Ordine effettuato con successo</h2>
                    {order.type === 'takeaway' ?
                      <p>Ti aspettiamo il {order?.takeaway?.date} alle {order?.takeaway?.time} per il ritiro</p> :
                      order.type === 'delivery' ? 
                      <p>Riceverai il tuo ordine il {order?.delivery?.date} intorno alle {order?.delivery?.time}</p> :
                      <p>Riceverai il tuo ordine quanto prima!</p>
                    }
                  </div>
                :
                  <div>
                    <h2>Ordine inviato con successo</h2>
                    <p>
                      Stiamo verificando la disponibilità nell'evasione del'ordine.
                      Riceverai una email di conferma appena possibile!
                      </p>
                  </div>
              }
              <button         
                  className="button block bottom"
                  onClick={goToOrder}>
                  VEDI ORDINE
              </button>
          </motion.div>
        }

        { (status === 'waiting') &&
          <motion.div 
            className="cart__resume cart__resume-success"
            variants={FADE_IN} 
            initial="start"
            animate="end">
              <WaitingIcon/>
              <h2>Stiamo elaborando il tuo ordine</h2>
              <p>Attendi un momento...</p>
          </motion.div>
        }

        { (status === 'error') &&
          <motion.div 
            className="cart__resume cart__resume-success"
            variants={FADE_IN} 
            initial="start"
            animate="end">
              
              <h2>Si è verificato un problema durante il pagamento</h2>
              <p>Contatta per favore i gestore del locale</p>
          </motion.div>
        }
      
      </AnimatePresence>
      </OpenCartPageWrapper>
  );
}

export default CartSuccess;

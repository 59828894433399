import React from 'react';

import { Link } from 'react-router-dom'

export default function Allergens({items, businessId})
{

    if(!items || items.length === 0)
        return null;

    return (
        <div className="allergens">
            <p>Allergeni</p>
            <div className="allergens-wrapper">
            <Link  
                to={`/${businessId}/allergens/${items.join(",")}`}>
                {(items).map(allergen => (                
                    <img 
                        className="allergen"
                        key={allergen} 
                        src={`/allergens/${allergen}.png`} 
                        alt={allergen} /> 
                ))}
            </Link>
            </div>
        </div>
    )
}
import { BusinessContext } from 'providers/BusinessProvider';
import React, { useState, useEffect, useContext } from 'react';

export const useBusiness = () => {
    const business = useContext(BusinessContext)
    const [isBusinessLoading, setLoading] = useState(true);

    useEffect( () => {
        if(business.id === undefined)
            return setLoading(true)

        setLoading(false);

    }, [business])


    return [isBusinessLoading, business]
}
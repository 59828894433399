import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "providers/CartProvider";
import ReactDOM from "react-dom"
import { useHistory } from "react-router-dom";
import { BounceLoader } from "react-spinners";

let PayPalButton = null;
function Paypal({business}) 
{

  const cart = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [loaded, setPaypal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if(business?.payment?.paypal?.publicKey === undefined)
       throw new Error('Paypal non configurato')

    if(!document.getElementById('paypal-script'))
      loadScript(business.payment.paypal.publicKey)
    else
      setPaypal(true);

   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const loadScript = function(clientId) {
    let node = document.createElement('script');
    node.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=EUR&disable-funding=credit,card,credit,mybank,sofort,venmo&intent=authorize`;
    node.type = 'text/javascript';
    node.async = true;
    node.id = 'paypal-script'
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
    node.addEventListener('load', paypalLoaded, {
      once: true
    });

  }

  function paypalLoaded()
  {
    initPayPalButton();    
  }

  function initPayPalButton() 
  {
    PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
    setPaypal(true)
  }

  let orderData;


  const createOrder = async (data, actions) => {  
    return orderData.sessionId;
  };

  const onClick =  async (asd, actions) => {    
      const resOrder = await cart.placeOrder();

      if(!resOrder)
        return actions.reject();

      orderData = resOrder;

      actions.resolve();
  }

  const onCancel = async(data) => {
    setLoading(true);

    const captureOrder = window.firebase.app()
      .functions('europe-west1')
      .httpsCallable('revertOrder');

    return captureOrder({
        orderId: orderData.orderId, 
        orderToken: orderData.orderToken,
        businessId: business.id
      })
    .then(function(details) {     
        
      })
    .catch(err => console.error(err))
    .then(() => setLoading(false));
  }


  const onApprove = (data, actions) => {
    setLoading(true);

    const captureOrder = window.firebase.app()
      .functions('europe-west1')
      .httpsCallable('paypalAuthorizeOrder');

    return captureOrder({
        orderId: orderData.orderId, 
        orderToken: orderData.orderToken, 
        sessionId: data.orderID,
        businessId: business.id
      })
    .then(function(details) {     
        if (details.error === 'INSTRUMENT_DECLINED') 
        {
          return actions.restart();
        }

        if(details.data === 200)
        {
          history.push({
            pathname:  `/${business.id}/cart/success`,
            search: history.location.search + 
                            '&orderId=' + orderData.orderId + 
                            '&orderToken=' + orderData.orderToken  +
                            '&type=paypal'
          });
        }  

        if(details.data === 500)
        {
          //TODO: mostrare errore
        }

      })
    .catch(err => console.error(err))
    .then(() => setLoading(false));
    
  };

  return (
    <div className="text-center margin-top-auto margin-bottom-1">
    {loading || !loaded ?  
      <div className="flex-center"><BounceLoader size={32}  /> </div>: 
      <PayPalButton
          style={{
            shape: 'pill', 
            size: 'responsive'
          }}
          label="pay"
          onClick={onClick}
          onCancel={onCancel}
          createOrder={createOrder}
          onApprove={onApprove}
          funding={{allowed:[ window.paypal.FUNDING.PAYPAL]}}
        /> }
    </div>
  );
}

export default Paypal;

/*
*/
import React, { useContext } from 'react';
import { CartContext } from 'providers/CartProvider';
import {formatPrice} from 'helpers';

import { useHistory, Link } from 'react-router-dom';

import PaiazoCheckbox from 'components/PaiazoCheckbox';
import SearchAddressInput from '../components/SearchAddressInput'
import CartTimeslot from './CartTimeslot';

import { motion } from 'framer-motion'

function UserInfo({ business }) 
{

  const cart = useContext(CartContext);
  const minOrderRespected = cart.type === 'delivery' && cart.totalPrice >= cart.minOrderForDelivery || cart.type !== 'delivery';

  const history = useHistory();

  return (
    <div className="cart__userInfo">

      <label>Per ricevere informazioni circa il tuo ordine lascia il tuo numero di telefono</label>
      {
        (business.askUserName || cart.type !== 'table') &&
        <input
          value={cart.user.name}
          className={cart.error?.validation?.name && 'error'}
          type="text"
          id="name"
          autoComplete="name"
          onChange={e => cart.setUser({ ...cart.user, name: e.target.value })}
          placeholder="Nome e Cognome"></input>
      }
      <input
        value={cart.user.telephone}
        className={cart.error?.validation?.telephone && 'error'}
        type="number"
        id="telephone"
        autoComplete="tel"
        onChange={e => cart.updateUser('telephone', e.target.value)}
        placeholder="Numero di telefono"></input>


      {cart.type !== 'table' && (
        <div>
          <input
            value={cart.user?.email}
            className={cart.error?.validation?.email && 'error'}
            id="email"
            type="email"
            autoComplete="email"
            onChange={e => cart.updateUser('email', e.target.value)}
            placeholder="Email"></input>

          <h4>Tipologia</h4>
          <fieldset id="type">
            {business.delivery?.active ? <label htmlFor="delivery">
              <input type="radio"
                onChange={() => cart.setType('delivery')}
                checked={cart.type === "delivery"}
                value="delivery"
                id="delivery"
                name="type" />
              Consegna a Domicilio {!minOrderRespected ? `(Ordine minimo ${formatPrice(cart.minOrderForDelivery)})` : ''}</label> : null}
            {business.takeaway?.active ? <label htmlFor="takeaway">
              <input type="radio"
                onChange={() => cart.setType('takeaway')}
                checked={cart.type === "takeaway"}
                value="takeaway"
                id="takeaway"
                name="type" />
              Asporto</label> : null}
          </fieldset>

          <motion.div
            animate={
              cart.type === 'delivery' ? 'open' : 'closed'
            }
          >
            <motion.div
              variants={{
                closed: { height: 0 },
                open: { height: 'auto' }
              }}
              transition={{
                type: "spring",
                stiffness: 60,
                restDelta: 2,
                duration: .5
              }}
              style={{ overflow: 'hidden' }}
            >
              <h4>Indirizzo di Consegna</h4>
              <div className="cart__checkout-address">
                <SearchAddressInput
                  cart={cart}
                  polygon={business?.delivery?.area}
                  value={cart.user?.address}//cart.user?.address?.google}
                  id="google-address"
                  onChange={(address) => cart.updateUser('address', address)}
                />

              </div>
            </motion.div>
          </motion.div>


          <CartTimeslot
            type={cart.type}
            business={business}
          />


          <PaiazoCheckbox
            id="over18"
            checked={cart.user.over18}
            onChange={id => cart.updateUser('over18', !cart.user.over18)}
            className={cart.error?.validation?.address?.over18 && 'error'}>
            Dichiaro e garantisco di aver compiuto i 18 anni di età
          </PaiazoCheckbox>
        </div>
      )}

      <PaiazoCheckbox
        id="privacy"
        checked={cart.user.privacy}
        onChange={id => cart.updateUser('privacy', !cart.user.privacy)}
        className={cart.error?.validation?.privacy && 'error'}>
        Dichiaro di aver preso visione e di accettare la&nbsp;
          <Link to={{ pathname: `/${business.id}/cart/privacy`, search: history.location.search }}>Privacy Policy</Link>
      </PaiazoCheckbox>

    </div>
  );

}

export default UserInfo;

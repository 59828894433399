import React from 'react';

import  QtyController from 'components/QtyController';
import {formatPrice} from 'helpers';

function CartItem({product, categoriesWithLimit = [], increment, decrement})
{
    const {name, price, qty, ingredients, notes, category_id} = product;

    const priceLabel = categoriesWithLimit && (categoriesWithLimit.find(i => i.id === category_id)?.limit||0) > 0 ? '' : formatPrice(price);

    return (
        <li>
            <div className="cart__resume-wrapper">
                <div>
                    <span className="cart__resume-title">{name}</span>
                    <div className="cart__resume-price">{priceLabel}</div>
                </div>
                <div className="cart__resume-qty">
                    <QtyController 
                                qty={qty}
                                increase={() => increment(product)}
                                decrease={() => decrement(product)}/>
                </div>
            </div>
            {ingredients && <div className="cart__resume-price">{ingredients.reduce((acc, i) => acc += `${i.name} ${formatPrice(i?.price)} - `, '').slice(0, -3)} </div>}
            {notes && <div className="cart__resume-price">Note: {notes} </div>}
        </li>
    );
}

export default CartItem;
import React from 'react';

function PaiazoCheckbox ({children, id, checked, onChange, className}) {
    return (
        <label className={`checkbox-container ${className}`}>
            <input type="checkbox" checked={checked} onChange={() => onChange(id)}/>
            <span className="checkmark"></span>
            {children}
        </label>
    )
}

export default PaiazoCheckbox;
import React from 'react';


const Alert = ({message}) => {

    if(message)
        return (
            <div className={`${message.type} alert`}>
                {message.text}
            </div>
        )

    return null;
}

export default Alert;
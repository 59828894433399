import React, { useEffect, useState, useContext } from 'react';
import Swiper from "swiper";
import { BounceLoader } from 'react-spinners';
import { BusinessContext } from '../../../providers/BusinessProvider';

let swiper;

function Categories({onChange, menu, categories}) {

  const [filteredCategories, setFilteredCategories] = useState([]);
 // const [categories, setCategories] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [twoRows, setTwoRows] = useState(false);
  const business = useContext(BusinessContext);

  useEffect(() => {
    if(categories.length === 0) return;

    setLoaded(true);
    loadSlider();
  }, [categories]);

  useEffect(() =>
  {
    if(!menu) return setFilteredCategories(categories);

    setFilteredCategories(
      categories.filter(i =>
        i.numberOfProducts > 0 && (
        i.id === '' ||
        i.menus === undefined ||
        ( Array.isArray(i.menus ) && i.menus.includes(menu) )
      )))
  }, [categories, menu]);

  useEffect(() =>
  {
    const initalIndex = (business.disableAllProductsCategory === true) ? 0 : 1

    onChange(filteredCategories[initalIndex]);

    if(filteredCategories)
      setTwoRows(filteredCategories.some(i => i.twoRows))
     // Fatto per aggirare problema che swiper non prende gli aggiornamento degli stati
      if(!swiper) return;

      swiper.eventsListeners.slideChange[0] = slideChange;
      swiper.slideTo(initalIndex);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCategories]);


  const loadSlider = () =>
  {

    if(swiper) return;

    swiper = new Swiper ('#categorySlider.swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 20,
      centeredSlides: true,
      slideToClickedSlide: true,
      initialSlide: business.disableAllProductsCategory === true ? 0 : 1,
      observer: true,
      on:{
        init: () => onChange(filteredCategories[1]),
        slideChange: slideChange
      },
    })
  }

  const slideChange = () =>
  {
    if(!swiper) return ;

    onChange(filteredCategories.filter(i => !i.twoRows)[swiper.realIndex]); //ToDo Controllo esistenza?
  }

  /*
  * Nel caso in cui si passi alla selezione su second row e poi si voglia
  * tornare allo slider principale
  */
  const tapOnSlide = event =>
  {
    removeActiveSecondRow();

    if(event.currentTarget.classList.contains('swiper-slide-active'))
      return;

    swiper.slideTo(event.currentTarget.dataset.index);
    onChange(filteredCategories[event.currentTarget.dataset.index]);
    event.currentTarget.classList.add('swiper-slide-active');
  }

  const selectSecondRowCategory = (category, event) =>
  {
    removeActiveSlides();
    event.currentTarget.classList.add('secondRow__category-active');
    onChange(category);
  }

  const removeActiveSlides = () =>
  {
    document.querySelectorAll('.category')
        .forEach( category => {
            if (category.classList.contains('swiper-slide-active')) {
              category.classList.remove('swiper-slide-active');
            }
        });

    removeActiveSecondRow();
  }

  const removeActiveSecondRow = () =>
  {
    document.querySelectorAll('.secondRow__category')
        .forEach( category => {
            if (category.classList.contains('secondRow__category-active')) {
              category.classList.remove('secondRow__category-active');
            }
        });
  }

  return (
    <>
      <div id="categorySlider" className="swiper-container categories">
        <div className="swiper-wrapper">
          {  loaded ?
                filteredCategories
                  .filter(i => !i.twoRows)
                  .map((i, index) => (
                    <Category
                      onClick={tapOnSlide}
                      key={i.id}
                      index={index}
                      twoRowsRender={twoRows||business.compactCategories}
                      {...i}
                    />
                  )) :
                <div className="spinner"><BounceLoader loading={!loaded}/></div>
            }
        </div>
        <div className="secondRow">
          {
            loaded &&
            filteredCategories
              .filter(i => i.twoRows)
              .map(i => (
                <div
                  className="secondRow__category"
                  key={i.id}
                  onClick={selectSecondRowCategory.bind(this, i)}>
                  {i.name}
                  </div>
              ))
          }
        </div>
      </div>
    </>
  );
}

function Category({name, icon, numberOfProducts, twoRowsRender, onClick, index}) {

  return (
    <div
        data-index={index}
      onClick={onClick}
      className={`swiper-slide category ${twoRowsRender ? 'category__two-rows' : ''}`}>
      <div>
        <i className={`category_icon icon-${icon}`}></i>
        {
          !twoRowsRender ?
            <span className="category_total">{numberOfProducts}</span> :
            <span className="category_title">{name}</span>
        }
      </div>
      {!twoRowsRender && <span className="category_title">{name}</span>}
    </div>
  )
}


export default Categories;

import React from "react";
export default function TextInput({ name, type = "text", register, error, ...props }) {

  return (
    <div className="input-wrapper">
        <input
            className={error && 'error'}
            type={type}
            {...register(name)}
            {...props}/>
        {error && <p className="input-error">{error?.message}</p>}
    </div>
  );
}


import React, { useState, useContext, useEffect } from 'react';
import {CartContext} from 'providers/CartProvider';

import { useHistory, useLocation } from "react-router-dom";

import { motion } from 'framer-motion';

import './style.scss';
import CartSwitch from './CartSwitch';
import { BusinessContext } from 'providers/BusinessProvider';
import { formatPrice } from 'helpers';


function Cart({hide}) {

  const [status, setStatus] = useState('close');

  const history = useHistory();
  const location = useLocation();


  const cart = useContext(CartContext);
  const business = useContext(BusinessContext);

  useEffect(() => {   
    // Se sono in una rotta del carrello apro il carrello
    if(location.pathname.search('/cart') !== -1)
     open(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {      
    if(!business.id) return;
    //Se il carrello è vuoto o non è presente un'orderId chiudo il carrello
    if(location.pathname.search('/cart') !== -1 && cart.size === 0 && location.search.search('orderId') === -1)
      close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart.size, location.pathname, business])


  useEffect(() => {  
      
    if(location.pathname.search('/cart') === -1)
    {
      document.getElementsByTagName('body')[0].classList.remove("noscroll");
      setStatus('close');
    }
      
  }, [location.pathname])



  function renderBarMessage()
  {
    if(cart.products.length === 0)
      return 'Aggiungi prodotti al carrello';

    return (
      <span>
        Hai <strong>{cart.size}</strong> piatti nell'ordine<br/> per un totale di&nbsp;
        <strong>{formatPrice(cart.totalPrice)}</strong>
      </span>
      );
  }

  const open = (redirect = true) => { 
    setStatus('open'); 
    document.getElementsByTagName('body')[0].classList.add("noscroll");
    
    if(redirect)
      history.push({
        pathname: `/${business.id}/cart/resume`,
        search: history.location.search
      });
  }

  function close()
  {
    const reference = (new URLSearchParams(history.location.search)).get('reference');
    history.push({
      pathname: `/${business.id}`,
      search: reference ? 'reference='+reference : '' 
    });
  }

  //
  return (
    <motion.div 
      id="cart" 
      className={hide ? 'hide' : status}>      
        <CartSwitch/>        
        {status === 'open' && (
          <motion.button 
          className="button block outline close" onClick={close}>
              CHIUDI
          </motion.button> 
          )}
        { cart.isShopOpen ? (
          <div className="cart__bar">
              <div>{renderBarMessage()}</div>       
              <div>
                  <button className="button" disabled={cart.size === 0} onClick={open}>ORDINA</button>
              </div>  
            </div>
        ) : (
          <div className="cart__bar text-center">
            Siamo spiacenti, ma per oggi non accettiamo più ordini. Riprova domani.
          </div> 
        )}
    </motion.div>
  );
}

export default Cart;

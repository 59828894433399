import React, {useState, useEffect, useContext} from 'react';

import QtyController from '../../components/QtyController';
import Modal from '../../components/Modal'
import './style.scss';


import IngredientsList from './components/IngredientsList'

import { useLocation, useHistory } from 'react-router-dom';
import { BusinessContext } from '../../providers/BusinessProvider';
import { CartContext } from '../../providers/CartProvider';

const DEFAULT_CART_MODAL = {qty: 1, notes: ''};


const AddToCardModal = () => {  

    const [product, setProduct] = useState(DEFAULT_CART_MODAL);
    const [cartModalData, setData] = useState(DEFAULT_CART_MODAL);
    const location = useLocation();
    const history = useHistory();
    const business = useContext(BusinessContext);
    const cart = useContext(CartContext);


    useEffect(() => {
        if(!location.state || !location.state.product)
            return history.push(`/${business.id}` + history.location.search);

        setProduct(location.state.product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 
    function updateData(field, value)
    {
        let tmpObj = {}; 
        tmpObj[field] = value;
        setData({...cartModalData, ...tmpObj});
    }
 
    function submit()
    {
        cart.addProduct({...product, ...cartModalData});
        history.push(`/${business.id}` + history.location.search);
    }


    function onIngredientsChange(ingredients)
    {
        updateData('ingredients', ingredients)
    }


    return (
        <Modal className={`add2CartModal`}>
            <h2 className="section_title">Opzioni</h2>
            <div className="add2CartModal__qty">
                <label>Quantità:</label> 
                <QtyController qty={cartModalData.qty}
                            increase={() => updateData('qty', cartModalData.qty+1)}
                            decrease={() => cartModalData.qty > 1 ? updateData('qty', cartModalData.qty-1) : null}
                ></QtyController>
            </div>

            { product && product.type === 'modular' &&
                <IngredientsList product={product} businessId={business.id} onChange={onIngredientsChange}/>
            }          

            <div>
                <label>Note</label>
                <textarea value={cartModalData.notes} onChange={event => updateData('notes', event.target.value)}/>
            </div>
        
            <div className="add2CartModal__buttons">
                <button className="button outline" onClick={
                    history.goBack}>Annulla</button>
                <button className="button" onClick={() => submit(cartModalData)}>Aggiungi all'ordine</button>
            </div>
        </Modal> 
    );
  };

export default AddToCardModal;


import React from 'react';
import ReactDOM from 'react-dom';

import 'swiper/swiper.scss';
import 'swiper/components/lazy/lazy.scss';
import 'swiper/components/lazy/';
import './scss/main.scss';

import Home from './pages/Home';

import CartProvider from './providers/CartProvider';
import BusinessProvider from './providers/BusinessProvider';

import {
  Router,
  Route,
  Switch
} from "react-router-dom";

import * as serviceWorker from './serviceWorker';

import createBrowserHistory from 'helpers/browserHistory';
import ShopProvider from "./providers/ShopProvider";
import CovidForm from 'pages/CovidForm';
import Privacy from 'pages/Privacy';


ReactDOM.render(
  <React.StrictMode>
    <Router history={createBrowserHistory}>
    <BusinessProvider>
      <Switch>
          <Route exact path={ '/:businessId/covid-form/'} component={CovidForm} />
          <Route exact path={ '/:businessId/privacy/'} component={Privacy} />
          <Route path={ '/:businessId/'} render={() => (
              <ShopProvider>
                <CartProvider>
                  <Home/>
                </CartProvider>
              </ShopProvider>
          )}  />
          </Switch>
      </BusinessProvider>
    </Router>
    <div className="copyright">
      <a rel="noopener noreferrer" target="_blank" href="https://www.paiazo.app">Paiazo</a> &copy;	{(new Date()).getFullYear()} by Acme Produzioni Srl
      </div>
  </React.StrictMode>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

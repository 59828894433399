import React from 'react';

import {
    Switch,
    Route,
   // useLocation
  } from "react-router-dom";
  
import CartResume from './pages/CartResume';
import CartCheckout from './pages/CartCheckout';
import CartSuccess from './pages/CartSuccess';
//import DummyMotion from 'components/DummyMotion';


const routes = [
   //{ path: '', name: 'DummyMotion', Component: DummyMotion },
   { path: '/:businessId/cart/resume', name: 'CartResume', Component: CartResume },
   { path: '/:businessId/cart/checkout', name: 'TestCheckout', Component: CartCheckout },
   { path: '/:businessId/cart/success', name: 'CartSuccess', Component: CartSuccess },
]


function ModalSwitch() {  
  //const location = useLocation();

  return (
    <Switch> 
      {routes.map(({ path, Component }) => {
        return <Route exact path={path} key={path} component={Component} />
      })}
    </Switch>    
  );
  }

export default ModalSwitch;
import React, { useState, useContext, useEffect } from 'react';
import {CartContext} from 'providers/CartProvider';

import { useHistory } from 'react-router-dom';

import  CartItem from '../components/CartItem';
import  CartUserInfo from '../components/CartUserInfo.js';
import { BusinessContext } from 'providers/BusinessProvider';
import OpenCartPageWrapper from '../components/OpenCartPageWrapper';
import { formatPrice } from 'helpers';
import {ShopContext} from "providers/ShopProvider";


function CartResume() 
{
  const [showScrollIndicator, setScrollIndicatorStatus] = useState(false);
  const [courses, setCourses] = useState(0);

  const history = useHistory();

  const cart = useContext(CartContext);  
  const business = useContext(BusinessContext);  
  const shop = useContext(ShopContext);

  useEffect(() => {
    setScrollIndicatorStatus(hasScrollbar);
  }, [cart.size]);

  function hasScrollbar()
  {
    const div = document.getElementById('cart__products-list'); 
    return div.scrollHeight>div.clientHeight;      
  }


  function goToPayments()
  {
    history.push({
      pathname: `/${business.id}/cart/checkout`,
      search: history.location.search
    });
  }

  useEffect(() =>
  {
    if(!business.reservation) return

    const productsPerMenuCategory = cart.products.reduce((acc, i) => {
      if(!acc[i.menu]) acc[i.menu] = {};

      acc[i.menu][i.category_id] = (acc[i.menu][i.category_id]||0) + 1*i.qty
      return acc;
    }, {})

    let newCourses = 0;

    for(let i = 0; i < business.reservation.menuLimits.length; i++)
    {
       const menuLimit = business.reservation.menuLimits[i];

       if(!productsPerMenuCategory[menuLimit.id]) continue;

      newCourses += menuLimit.categories.reduce((acc, j) => {
          if(productsPerMenuCategory[menuLimit.id][j.id])
          {
             const courseInCategory = parseInt(j.limit) > 0  ?
                 Math.ceil(productsPerMenuCategory[menuLimit.id][j.id] / parseInt(j.limit)) :
                 0;
             return courseInCategory > acc ? courseInCategory : acc;
          }
          return acc;
       }, 0)
    }

    setCourses(newCourses)
  }, [cart.products, business.reservation])


  return (
    <OpenCartPageWrapper>
        <h2 className="section_title">Riepilogo Ordine</h2>
        <ul id="cart__products-list" className={showScrollIndicator ? 'showScroll' : 'hideScroll'}>
          {cart.products.map(i => <CartItem key={i.localId}
                                            product={i}
                                            increment={cart.incrementProductQty}
                                            decrement={cart.decrementProductQty}
                                            categoriesWithLimit={business?.reservation && business.reservation.menuLimits.find(j => j.id === i.menu)?.categories}
                                            menu={shop.menu} />)}
        </ul>
        {cart.type === 'delivery' &&
        <div className="cart__resume-delivery"> 
          <span className="cart__resume-label">Consegna:</span>
          <span className="cart__resume-value">{business?.delivery?.cost ? formatPrice(business?.delivery?.cost) : '€ 0,00' }</span>
        </div>  
        }
        { business.reservation && (
            <div className="cart__resume-delivery">
                <span className="cart__resume-label">Numero Coperti:</span>
                <span className="cart__resume-value">{courses}</span>
            </div>
        )}
        <div className="cart__resume-total">         
          <span className="cart__resume-label">Totale:</span>
          <span className="cart__resume-value">{formatPrice(cart.totalPriceWithDelivery) }</span>
        </div>
        { true ?
          (
            <button className={`button block continue`} onClick={goToPayments}>
              CONTINUA
            </button>
          ) :
          <CartUserInfo/> 
        }
      </OpenCartPageWrapper>
    );

}

export default CartResume;

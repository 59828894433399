import { PAYMENT_METHODS } from 'config';
import _upperFirst from 'lodash/upperFirst';

const make = async (type) => 
{
  const isMethodValid = PAYMENT_METHODS.find(i => i.id === type);

  const className = _upperFirst(type);

  if(isMethodValid)
    return await import(`./PaymentMethods/${className}.js`);

    
  return false;
  //TODO throw excepiton    
}

export default make;

import React from "react";
export default function Select({ name, options= [], register, error, placeholder="Seleziona un elemento", ...props }) {
  
  return (
    <div className="input-wrapper" style={{marginBottom: '1em'}} >
      <select    
        className={error && 'error'}
            {...register(name)}
            {...props}>
              <option value="">{placeholder}</option>
        {options.map(i => <option key={i.id} value={i.id}>{i.label}</option>)}
      </select>
        {error && <p className="input-error">{error?.message}</p>}
    </div>
  );
}


import React from 'react';

import {
    Switch,
    Route,
    useLocation
  } from "react-router-dom";
  
import { AnimatePresence } from "framer-motion"

import AddToCart from './pages/AddToCart'
import Privacy from './pages/Privacy/index';
import Product from './pages/Product';
import Order from './pages/Order';
import Orders from './pages/Orders';
import Allergens from './pages/Allergens';
import DummyMotion from './components/DummyMotion';
import DeliveryArea from "./pages/DeliveryArea";


const routes = [
  { path: '/product', name: 'Prodotto', Component: Product },
  { path: '/delivery-area', name: 'Area Consegna', Component: DeliveryArea },
  { path: '/allergens/:items', name: 'Allergeni', Component: Allergens },
  { path: '/addToCart/:productId', name: 'Carrello', Component: AddToCart },
  { path: '/cart/privacy', name: 'Privacy', Component: Privacy },
  { path: '/orders', name: 'Ordini', Component: Orders },
  { path: '/order', name: 'Ordine', Component: Order },
  { path: '/', name: 'DummyMotion', Component: DummyMotion },
]


function ModalSwitch() {
    const location = useLocation();

    return (
      <AnimatePresence> 
        <Switch location={location} key={location.pathname}>
          {routes.map(({ path, Component }) => (
              <Route exact path={ '/:businessId' + path} key={path}  component={Component} />
          ))}
        </Switch>
      </AnimatePresence>
    );
  }

  export default ModalSwitch;
import React from 'react';
import { BounceLoader } from 'react-spinners';


const Button = (props) => {
  const {loading, label, onClick, className='', disabled = false} = props;
  return (
    <button 
      disabled={disabled}
      className={`button block bottom ${loading ? 'loading' : ''} ${className}`} 
      onClick={!loading ? onClick : null}>
      { 
        loading ? 
          <div className="spinner"><BounceLoader size={32} /></div> : 
          label
      }
  </button>
)}

export default Button;
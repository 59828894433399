import React, { useContext, useEffect } from 'react';
import { CartContext } from 'providers/CartProvider';

import dayjs from 'dayjs';
import dayjs_it from 'dayjs/locale/it';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { BOOKABLE_DATE_FORMAT, CALENDAR_DATE_FORMAT } from 'config';

dayjs.extend(customParseFormat)
export default function CartTimeslot ({type})
{
    const cart = useContext(CartContext)

    useEffect( () => {        
        //cart.updateDeliveryTakeaway('date', '', type)
        cart.updateDeliveryTakeaway('time', '', type)
    }, [type])

    useEffect(() => {
        
        const availableDays = Object.keys(cart.timeslots)
        let defaultDate = availableDays[0]
        
        if(cart[type]?.date && availableDays.includes(cart[type].date))
            defaultDate = cart[type].date;

        cart.updateDeliveryTakeaway('date',defaultDate, type)
    },[cart.timeslots, type])

   
    function changeDateTimeSlot(value)
    {        
        cart.updateDeliveryTakeaway('date', value, type)
        cart.updateDeliveryTakeaway('time', value, type)
    }

    return (
        <div>
            {Object.keys(cart.timeslots).length > 0 ? (
            <div>
                <select
                required
                value={cart[type] ? cart[type].date : ''}
                onChange={e => changeDateTimeSlot(e.target.value)} 
                id="delivery_date"
                >
                    { Object.keys(cart.timeslots).map(date => (
                        <option key={date} value={date}>
                            {dayjs(date, CALENDAR_DATE_FORMAT)
                                .locale('it')
                                .format(BOOKABLE_DATE_FORMAT)}
                        </option>
                    ))}
                </select>
                <select
                required
                value={cart[type] ? cart[type].time : ''}
                onChange={e => cart.updateDeliveryTakeaway('time', e.target.value, type)} 
                className={cart.error?.validation?.delivery_time && 'error'} 
                id="delivery_time"
                >
                    <option value="" hidden>
                        {type === 'delivery' ?
                            'Orario di Consegna' :
                            'Orario ritiro asporto'
                        }
                    </option>
                    { (cart.timeslots[cart[type].date]||[]).map(hour =>                      
                        <option key={hour} value={hour}>{hour}</option>
                    )}
                </select>
            </div> ) : (
                <p className="alert error cart__notimeslots ">
                   Ci dispiace ma non è più possibile 
                   {cart.type === 'delivery' ? ' la consegna ' : ' l\'asporto '} 
                   in data odierna. 
                   Riprova domani :)
                </p>  
            )}
        </div>
          
    )
}
import React, {useEffect, useState} from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import createBrowserHistory from 'helpers/browserHistory';
import dayjs from "dayjs";
import {CALENDAR_DATE_FORMAT} from "../config";
import { BounceLoader } from 'react-spinners';
import { motion } from 'framer-motion';
import ErrorPage from 'pages/Home/components/ErrorPage';
const db = window.firebase.firestore();

function BusinessProvider(props)
{
    const [business, setBusiness] = useState({});
    const [reservation, setReservation] = useState(false);
    const [qrCode, setQrCode] = useState(false);

    const history = useHistory();
    const location = useLocation();


    useEffect(() => {
      db.enablePersistence()
        .catch(function(err) {
          if (err.code === 'failed-precondition') {
          } else if (err.code === 'unimplemented') { }
      });


      const queryParams = new URLSearchParams(window.location.search);
      const qrCodeId = queryParams.get("reference");

      if(qrCodeId)
        return loadBusinessByQrCode(qrCodeId);

      const matchPathResult = matchPath(createBrowserHistory.location.pathname, {
        path: "/:businessId/"
      })

      if(matchPathResult?.params?.businessId)
        return loadBusinessById(matchPathResult?.params?.businessId)

      console.error('Impossibile caricare business');
      setBusiness({notExists: true});
      //TODO: Visualizzazione pagina di errore
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      // se viene aggiornato il valore id del business e l'utente si trova
      // nella rotta senza il parametro :businessId
      if(business.id && location.pathname === '/')
        history.push({
          pathname: '/' + business.id,
          search: history.location.search
        })

      if(!business.color) return ;

      for (let [key, value] of Object.entries(business.color)) {
        document.documentElement.style.setProperty(`--color-${key}`, value);
      }

      if(business.color.primary)
        document.documentElement.style.setProperty(`--swiper-preloader-color`, business.color.primary);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [business])


    function loadBusinessByQrCode(qrCodeId)
    {
      if(!qrCodeId) return console.error('no qr code')

      db.collection(`qrCodes/`).doc(qrCodeId).get()
        .then(async qrCode => {
            await loadGroup(qrCode.data())
            setQrCode(qrCode.data())
            loadBusinessById(qrCode.data().businessId)
        })
        .catch(error => {
          //TODO: Error
          console.error('Init error', error)
        });
    }

    async function loadGroup(qrCodeData)
    {
        const todayReservation = qrCodeData?.reservation && qrCodeData?.reservation[dayjs().format(CALENDAR_DATE_FORMAT)];

        if(todayReservation)
        {
            const reservationDoc = await db.collection(`business/${qrCodeData.businessId}/${todayReservation.type}`).doc(todayReservation.id).get()
            setReservation(reservationDoc.data())
        }
    }

    function loadBusinessById(id)
    {
      db.collection(`business`).doc(id).get()
        .then(business => {
          if(business.data() === undefined){
            setBusiness({notExists: true});
            return false;
          }
          setBusiness({...business.data(), id: business.id})
        })
        .catch(error => {console.error('Init error', error)
      });
    }

    const stateToPublic = {
           ...business,
           qrCode,
           reservation: reservation,
           allowOrder: (business?.payment?.methods||[]).length > 0 && business?.blockOrder !== true
    };

    return (
        <BusinessContext.Provider value={stateToPublic}>
            { business.notExists ? <ErrorPage /> :
            business.id === undefined  ?  (
            <motion.div className="spinner fullscreen fullflex flex-center">
              <BounceLoader loading={true}/>
            </motion.div> )
            :
            props.children
            }
        </BusinessContext.Provider>
    );
}

export default BusinessProvider;

const BusinessContext = React.createContext({});

export { BusinessContext }

import React, {useState, useEffect} from 'react';

const QtyController = ({decrease, increase, qty }) => 
{  
  const [animateNumber, setNumberAnimation] = useState('');


    useEffect( () => {
      setNumberAnimation('bounce');
      setTimeout(() => setNumberAnimation(''), 100)
    }, [qty]);


    return (
      <div className="qty-controller">
        <div className="qty-controller__action" onClick={decrease}>-</div>
        <div className={`qty-controller__value ${animateNumber}`}>{qty}</div>
        <div className="qty-controller__action" onClick={increase}>+</div>
      </div>
    );
  };

export default QtyController;
import React, {useContext} from 'react';
import { BusinessContext } from '../../providers/BusinessProvider';
import Modal from '../../components/Modal'
import { useHistory } from 'react-router-dom';


const Privacy = () => {  

    const business = useContext(BusinessContext);
    const history = useHistory();

    return(
        <Modal className={`add2CartModal`}>
        <div style={{overflow: 'scroll'}}>
        <p><strong>Informativa sul trattamento dei dati personali</strong></p>
            <p><strong>Art. 13 del Reg. UE 2016/679 &ndash; GDPR</strong></p>
            <p>Nel rispetto dell&rsquo;art. 13 del Regolamento Generale sulla Protezione dei dati &ndash; Reg. UE 2016/679 (GDPR), ti forniamo le seguenti informazioni sul trattamento dei tuoi dati personali.</p>
            <p>La presente informativa sul trattamento dei dati personali riguarda esclusivamente l&rsquo;utilizzo della presente applicazione.</p>
            <p><strong>Dati personali trattati</strong></p>
            <p>I dati personali che ti richiediamo al momento di completare l&rsquo;ordine sono:</p>
            <ul>
            <li>
            <p>nome e cognome</p>
            </li>
            <li>
            <p>indirizzo</p>
            </li>
            <li>
            <p>nr. di Telefono</p>
            </li>
            <li>
            <p>email</p>
            </li>
            </ul>
            <p><strong>Finalit&agrave; del trattamento e natura del conferimento dei dati</strong></p>
            <p>I dati personali che ti richiediamo sono necessari per ricevere ed elaborare la tua ordinazione e per consegnarti ci&ograve; che hai ordinato. In mancanza, non potremo farti avere il cibo e le bevande che hai richiesto. </p>
            <p>Utilizzeremo i tuoi dati anche per contattarti nel caso avessimo la necessit&agrave; di comunicare con te circa la tua ordinazione.</p>
            <p><strong>Titolare del trattamento</strong></p>
            <p>Il Titolare del trattamento dei dati personali &egrave; {business.commercialDescription}, P.I. ---, con sede in {business.legalAddress}, proprietario di {business.name}</p>
            <p><strong>Base giuridica del trattamento (art. 6, par. 1, lettera b del GDPR)</strong></p>
            <p>Trattiamo i dati personali sopra descritti in quanto sono necessari:</p>
            <ul>
            <li>
            <p>per l&rsquo;esecuzione di un contratto di cui sei parte o di misure precontrattuali su tua richiesta (l&rsquo;ordinazione, l&rsquo;acquisto e la consegna di cibo e bevande).</p>
            </li>
            </ul>
            <p><strong>Durata del trattamento</strong></p>
            <p>I dati personali che ti richiediamo saranno utilizzati fino alla chiusura dell&rsquo;ordine di cibo e bevande che hai effettuato. </p>
            <p><strong>Modalit&agrave; del trattamento</strong></p>
            <p>Il trattamento dei tuoi dati personali avviene attraverso mezzi informatici e/o telematici, con l&rsquo;utilizzo di procedure e strumenti idonei a garantirne la sicurezza e la riservatezza. </p>
            <p><strong>Destinatari dei dati personali</strong></p>
            <p>I dati personali che ti richiediamo per effettuare l&rsquo;ordine saranno trattati:</p>
            <ul>
            <li>
            <p>dal nostro personale, espressamente a ci&ograve; autorizzato, nello svolgimento delle proprie mansioni;</p>
            </li>
            <li>
            <p>da ACME Produzioni Srl, nostro collaboratore nella fornitura dei servizi informatici, nominato responsabile esterno del trattamento ai sensi dell&rsquo;art. 28 del GDPR;</p>
            </li>
            <li>
            <p>soggetti, pubblici e privati, che possono accedere ai dati in forza di disposizione di legge, in caso di loro espressa richiesta (es. organi di controllo, forze dell&rsquo;ordine o magistratura).</p>
            </li>
            </ul>
            <p>Per la consegna di quanto hai ordinato potremmo servirci di un fornitore esterno, nominato se del caso responsabile esterno del trattamento ai sensi dell&rsquo;art. 28 del GDPR.</p>
            <p>I tuoi dati personali non saranno comunicati ad ulteriori soggetti terzi n&eacute; saranno diffusi.</p>
            <p><strong>Profilazione</strong></p>
            <p>I dati personali che ti richiediamo non saranno utilizzati per attivit&agrave; di profilazione, cio&egrave; non utilizzeremo i tuoi dati per l&rsquo;identificazione, anche mediante elaborazioni elettroniche, dei tuoi comportamenti, dei tuoi gusti e delle tue abitudini di consumo al fine di migliorare i nostri servizi ed inviarti offerte e proposte commerciali di tuo interesse.</p>
            <p><strong>Trasferimento dei dati in Paesi extra Unione Europea</strong></p>
            <p>Il trattamento dei dati personali attraverso questa applicazione non comporta il trasferimento dei dati in Paesi non facenti parte dell&rsquo;Unione Europea.</p>
            <p><strong>Diritti dell&rsquo;Interessato (articoli da 15 a 22 del GDPR)</strong></p>
            <p>In qualit&agrave; di Interessato, hai diritto di: <strong>1</strong>. accedere ai tuoi dati personali per ottenere informazioni sui dati trattati; <strong>2</strong>. chiedere la rettifica o l&rsquo;aggiornamento dei tuoi dati personali; <strong>3</strong>. opporti al trattamento dei tuoi dati, come sotto indicato; <strong>4</strong>. ottenere la limitazione del trattamento; <strong>5</strong>. ottenere la cancellazione dei tuoi dati personali, quando ricorrono le condizioni indicate dalla legge; <strong>6</strong>. ricevere i tuoi dati personali o farli trasferire, ove tecnicamente possibile, ad altro titolare; <strong>7</strong>. revocare il consenso eventualmente prestato. La revoca del consenso non fa venir meno la liceit&agrave; del trattamento effettuato prima della revoca; <strong>8</strong>. proporre reclamo al Garante Privacy per la Protezione dei dati (cd. Garante Privacy) o ricorrere all&rsquo;Autorit&agrave; giudiziaria.</p>
            <p><em>Dettagli sul diritto di opposizione</em></p>
            <p>Quando i dati personali sono trattati nell&rsquo;interesse pubblico, nell&rsquo;esercizio di pubblici poteri di cui &egrave; investito il Titolare oppure per perseguire un interesse legittimo del Titolare, l&rsquo;Interessato ha diritto ad opporsi al trattamento per motivi connessi alla sua situazione particolare. Se i dati personali sono trattati con finalit&agrave; di marketing diretto, l&rsquo;Interessato pu&ograve; opporsi al trattamento senza fornire alcuna motivazione.</p>
            <p><strong>Modalit&agrave; di esercizio dei diritti </strong></p>
            <p>Potrai esercitare i diritti sopra indicati inviando la tua richiesta al Titolare del trattamento con una email all&rsquo;indirizzo {business.email} o con lettera raccomandata spedita a {business.commercialDescription} -  {business.legalAddress}</p>
        </div>
        <button className="button" onClick={history.goBack}>CHIUDI</button>

        </Modal>
    )
   
  };

export default Privacy;
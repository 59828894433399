const DEFAULT_PAYMENT_METHOD = 'card';

export const BOOKABLE_DATE_FORMAT = 'dddd DD MMMM'
export const CALENDAR_DATE_FORMAT = 'DD-MM-YYYY'
export const CALENDAR_DAY_NAME_FORMAT = 'dddd'
export const WEEKDAYS = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
]

export const DEFAULT_BOOKABLE_DAYS = 1

const PAYMENT_METHODS = [
  {id: 'stripe', icon:'credit-card-alt', label: 'Carta di Credito'},
  {id: 'cash', icon:'dollar', label: 'Pagamento in Contanti'},
  {id: 'paypal', icon:'paypal', label: 'Paypal'},
]

const FULLPAGE_PRODUCT_TRANSITION_TIME_MS = 300

const DEFAULT_TRANSITION = { transition:  {
    duration: 3
  } 
}

const FADE_IN = {
  start:{ opacity: 0 },
  end:{ opacity:1 },
  ...DEFAULT_TRANSITION
}

const SLIDE_IN_TRANSITION = {
    ease: "easeInOut",
    duration: .3,
    type: "tween",
  } 

const SLIDE_IN = {
  start:{
    x: -600,  
    opacity:0,
    transition: {...SLIDE_IN_TRANSITION}
   },
  mid:{ 
    x: 0,  
    opacity: 1,
    transition: {...SLIDE_IN_TRANSITION}
  },
  end:{ 
    x: 600, 
    opacity: 0,
    transition: {
      ...SLIDE_IN_TRANSITION,
      delay: .3
    }
   },
}

export {
    DEFAULT_PAYMENT_METHOD,
    PAYMENT_METHODS,
    FULLPAGE_PRODUCT_TRANSITION_TIME_MS,
    DEFAULT_TRANSITION,
    FADE_IN,
    SLIDE_IN
}
import dayjs from 'dayjs';

export const getReference = (queryParams) => {
    const reference = (new URLSearchParams(queryParams)).get('reference');

    return reference ? `reference=${reference}` : ''
}

export const cleanObject = (obj) => {
    Object.keys(obj).forEach(key =>
     // eslint-disable-next-line
      (obj[key] && typeof obj[key] === 'object') && cleanObject(obj[key]) ||
      // eslint-disable-next-line
      (obj[key] === undefined || obj[key] === null || obj[key] === "") && delete obj[key]
    );
    return obj;
  };
  

export const getActiveTimeslot = (timeslots) => {
  if(!timeslots)
    return [];
    
  return Object.keys(timeslots)
            .sort()
            .filter(i => {
                const slots = timeslots[i];
                const timeSplitted = i.split(':');
                const todayTimeslot = dayjs().hour(timeSplitted[0]).minute(timeSplitted[1]).second(0);
                
                return todayTimeslot.isAfter(dayjs()) && slots && parseInt(slots) > 0
            })
}

export const formatPrice = (price, decimal = 2) =>
{
    if (price === 0) return '0 €';

    if (!price) return '';

    return `€ ${price.toFixed(decimal).replace('.',',')}`;
};
import React, {useContext, useEffect} from 'react';


import Modal from '../../components/Modal'
import './style.scss';


import { BusinessContext } from '../../providers/BusinessProvider';
import {useHistory} from "react-router-dom";

let map;

const DeliveryArea = () => {

    const business = useContext(BusinessContext);
    const history = useHistory();

    useEffect(() => {

        map = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8,
        });

        const modal = document.getElementsByClassName('modal delivery-area')

        if(modal && modal[0]){
            modal[0].addEventListener('click', history.goBack )
            document.getElementById('map').addEventListener('click', e => e.stopPropagation());


        }

    }, []);

    useEffect(() => {
        if(!business.delivery) return;

        const deliveryAreaPolygon = new window.google.maps.Polygon({
            paths: business.delivery.area,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
        });
        deliveryAreaPolygon.setMap(map);

        const bounds = new window.google.maps.LatLngBounds();

        deliveryAreaPolygon.getPath().forEach(path => bounds.extend(path));

        map.fitBounds(bounds);

    }, [business]);


    return (
        <Modal className={`delivery-area`}>
            <div id="map"></div>
        </Modal> 
    );
  };

export default DeliveryArea;

